interface IIcon {
  width: string;
  height: string;
}

export const IconCreditRating = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1V7.33275V7.30775V17V1ZM3.5 10.5H7.09625C7.19492 10.309 7.30133 10.1333 7.4155 9.973C7.5295 9.81283 7.65125 9.65517 7.78075 9.5H3.5V10.5ZM3.5 14.5H6.3865C6.3545 14.3333 6.33242 14.1667 6.32025 14C6.30808 13.8333 6.30583 13.6667 6.3135 13.5H3.5V14.5ZM1.6155 18C1.15517 18 0.770833 17.8458 0.4625 17.5375C0.154167 17.2292 0 16.8448 0 16.3845V1.6155C0 1.15517 0.154167 0.770833 0.4625 0.4625C0.770833 0.154167 1.15517 0 1.6155 0H9.5L14 4.5V7.51925C13.8372 7.46275 13.6724 7.42267 13.5057 7.399C13.3391 7.37533 13.1705 7.35325 13 7.33275V5H9V1H1.6155C1.4615 1 1.32042 1.06408 1.19225 1.19225C1.06408 1.32042 1 1.4615 1 1.6155V16.3845C1 16.5385 1.06408 16.6796 1.19225 16.8078C1.32042 16.9359 1.4615 17 1.6155 17H7.3905C7.516 17.1948 7.65567 17.3733 7.8095 17.5355C7.9635 17.6977 8.12508 17.8525 8.29425 18H1.6155ZM12.5 16.3078C13.2897 16.3078 13.9551 16.0372 14.4962 15.4962C15.0372 14.9551 15.3077 14.2897 15.3077 13.5C15.3077 12.7103 15.0372 12.0449 14.4962 11.5037C13.9551 10.9627 13.2897 10.6923 12.5 10.6923C11.7103 10.6923 11.0449 10.9627 10.5038 11.5037C9.96275 12.0449 9.69225 12.7103 9.69225 13.5C9.69225 14.2897 9.96275 14.9551 10.5038 15.4962C11.0449 16.0372 11.7103 16.3078 12.5 16.3078ZM17.6 19.2885L14.823 16.5115C14.4987 16.7705 14.1388 16.9679 13.7432 17.1038C13.3477 17.2397 12.9333 17.3078 12.5 17.3078C11.4423 17.3078 10.5433 16.9375 9.803 16.197C9.0625 15.4567 8.69225 14.5577 8.69225 13.5C8.69225 12.4423 9.0625 11.5433 9.803 10.803C10.5433 10.0625 11.4423 9.69225 12.5 9.69225C13.5577 9.69225 14.4567 10.0625 15.197 10.803C15.9375 11.5433 16.3078 12.4423 16.3078 13.5C16.3078 13.9333 16.2397 14.3477 16.1038 14.7432C15.9679 15.1388 15.7705 15.4987 15.5115 15.823L18.2885 18.6L17.6 19.2885Z"
      fill="#263958"
    />
  </svg>
);
