import React from "react";
import * as Styled from "../stylesView&Create";
import { useFormik } from "formik";
import { OportunityContext } from "@context/oportunityContex";
import Spinner from "@components/common/Spinner";
import { MdChecklist, MdOutlineEditNote } from "react-icons/md";
import Button from "@components/common/Button";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import MessageToast from "@components/Sales/MessageToast";
import quotesService from "@services/Quotes/QuotesService";
import AccordionQuotesComponent from "../../components/AccordionQuotesComponent";
import { AiOutlineInfoCircle } from "react-icons/ai";
import TablePortion from "@components/Quotes/TablePortion";
import TableProposalAnalysis from "@components/Quotes/TableProposalAnalysis";
import { IValidationQuotesSchema } from "src/@types/Quotes";
import Modal from "@components/Sales/Modal";
import CreateMensaPsComponent from "./CreateMensaPsComponent";
import { BiPlus, BiSend } from "react-icons/bi";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import { RxUpdate } from "react-icons/rx";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { IViewQuotesComponent } from "src/@types/Sales";
import ViewChartsComponent from "./ViewChartsComponent";
import UpdateDataClientComponent from "../../components/UpdateDataClientComponent";
import UpdateAdressComponent from "../../components/UpdateAdressComponent";
import AlertCompareValue from "@components/Sales/AlertCompareValue";
import { UsuallyContext } from "@context/usuallyContex";

const ViewQuotesComponent = ({
  hash,
  isEdit = false,
  setIsEdit,
  canEdit,
}: IViewQuotesComponent) => {
  const { signOut } = React.useContext(LoginContext);
  const { hashDetailOportunity } = React.useContext(UsuallyContext);
  const {
    nomeConta,
    accessQuote,
    accessCreateOp,
    faseOp,
    setPaymentCondition,
    paymentCondition,
    accessCreditRating,
    setIsSendToApproval,
    isSendToApproval,
    disabledQuote,
    setSuccessUpdateAddresModal,
    setIsUpdateAdress,
    setIsUpdateAdressIncome,
  } = React.useContext(OportunityContext);
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const [successEditQuotes, setSuccessEditQuotes] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [dataQuotes, setDataQuotes] = React.useState<IValidationQuotesSchema>();

  const [loadingApproval, setLoadingApproval] = React.useState(false);

  const [loadingQuotes, setLoadingQuotes] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [creditRating, setCreditRating] = React.useState("");
  const [selectCreditRating, setSelectCreditRating] = React.useState("");
  const [selectCreditRatingId, setSelectCreditRatingId] = React.useState("");
  const [dataCreditRating, setDataCreditRating] = React.useState([]);
  const [loadingCreditRating, setLoadingCreditRating] = React.useState(false);
  const [discount, setDiscount] = React.useState("");

  const [showCreateMensalPs, setShowCreateMensalPs] = React.useState(false);
  const [successCreateMensalPs, setSuccessCreateMensalPs] =
    React.useState(false);
  const [maxQuantity, setMaxQuantity] = React.useState(0);

  const [dataProposalAnalysis, setDataProposalAnalysis] = React.useState([]);
  const [existMensalPs, setExistMensalPs] = React.useState(false);
  const [emptyPortion, setEmptyPortion] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [parcelamentoSalvo, setParcelamentoSalvo] = React.useState(false);
  const [parcelaAdicionadaNaoSalva, setParcelaAdicionadaNaoSalva] =
    React.useState(false);
  const [openCharts, setOpenCharts] = React.useState(false);
  const [showModalUpdateDate, setShowModalUpdateDate] = React.useState(false);
  const [showModalUpdateAdress, setShowModalUpdateAdress] =
    React.useState(false);
  const [bonusAdimplencia, setBonusAdimplencia] = React.useState(0);
  const [valorFinalKit, setValorFinalKit] = React.useState(0);
  const [valorTotalUnidade, setValorTotalUnidade] = React.useState(0);
  const [compareValue, setCompareValue] = React.useState(false);

  const handleSalvarParcelamento = () => {
    setParcelamentoSalvo(true);
    setParcelaAdicionadaNaoSalva(false);
  };

  const handleAdicionarParcela = () => {
    setParcelaAdicionadaNaoSalva(true);
    setParcelamentoSalvo(false);
  };

  const disableEdit = dataQuotes?.status?.includes("nálise");

  const disableApprove = dataQuotes?.status?.toLowerCase()?.includes("aprovad");

  const valueNumberFormat = (valor: any) => {
    const newValor = valor
      ?.toString()
      .replace("R$", "")
      .replace(".", "")
      .replace(",", ".");
    const valorNumerico = parseFloat(newValor);
    return valorNumerico;
  };

  const formatMoney = (valor: any) => {
    const newValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
    return newValue;
  };

  const initialValues = {
    nameAccount: nomeConta || "",
    proposalDate: dataQuotes?.dataProposta || "",
    paymentTerms: dataQuotes?.nomeCondicaoPagamentoMatrizSelecionada || "",
    status: dataQuotes?.status || "",
    product: dataQuotes?.nomeProduto || "",
    observations: dataQuotes?.observacao || "",

    fgtsCalculated: formatMoney(dataQuotes?.fgtsApurado) || "",
    subsidyAssessed: formatMoney(dataQuotes?.subsidioAvaliado) || "",
    financingCreditAssessment:
      formatMoney(dataQuotes?.financiamentoAvaliacaoDeCredito) || "",
    calculatedIncome: formatMoney(dataQuotes?.rendaApurada) || "",
    olderAge: dataQuotes?.idadeMaisVelho || 0,
    bankFee: dataQuotes?.taxaBanco || 0,

    income: formatMoney(dataQuotes?.renda) || "",
    totalUnitValue: dataQuotes?.valorTotalUnidade || 0,
    defaultBonusValue: formatMoney(dataQuotes?.valorBonusAdimplencia) || "",
    discount: formatMoney(dataQuotes?.desconto) || "",
    financingProvision: formatMoney(dataQuotes?.prestacaoFinanciamento) || "",

    balance: dataQuotes?.saldo || 0,
    totalBalanceInterest: dataQuotes?.saldoTotalComJuros || 0,
    incomeCommitmentInstallment: dataQuotes?.comprometimentoRendaParcela || 0,

    incomeCommitmentProSoluto: dataQuotes?.comprometimentoRendaProSoluto || 0,
    proSolutoInterspersed: dataQuotes?.proSolutoMensalIntercaladas || 0,
    proSolutoTotal: dataQuotes?.proSolutoTotal || 0,
    proSolutoPercentage: dataQuotes?.percentualProSoluto || 0,
    proSolutoDeficiency: dataQuotes?.proSolutoComCarencia || 0,
    proSolutoMonthly: dataQuotes?.proSolutoMensal || 0,
    maximumMonthlyIncome: dataQuotes?.maximoRendaMensal || 0,
    higherProSoluto: dataQuotes?.maiorValorProSoluto || 0,
    psPostWork: dataQuotes?.psPosObra || 0,
    psPostWorkPercent: dataQuotes?.psPsObraPercentual || 0,
    interleavedIncome: dataQuotes?.rendaIntercaladaPercentual || 0,
    totalMonthsCorrection: dataQuotes?.totalMesesCorrecao || 0,

    totalAmountFees: dataQuotes?.valorTotalTaxas || 0,
    rateInterest: dataQuotes?.jurosTaxa || 0,
    numberFeeInstallments: dataQuotes?.quantidadeParcelasTaxa || 0,
    feePortion: dataQuotes?.parcelaTaxa || 0,
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoadingUpdate(true);
      setSuccessEditQuotes(false);
      setError(false);
      try {
        const response = await quotesService.updateQuote({
          idAvaliacaoCreditoSalesforce:
            selectCreditRatingId === ""
              ? accessCreditRating.idAvaliacaoCreditoSalesforce
              : selectCreditRatingId,
          idCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
          desconto: valueNumberFormat(values.discount),
          jurosTaxa: Number(values.rateInterest),
          quantidadeParcelasTaxa: Number(values.numberFeeInstallments),
          valorTotalTaxas: valueNumberFormat(values.totalAmountFees),
          fgtsApurado: valueNumberFormat(values.fgtsCalculated),
          financiamentoAvaliacaoDeCredito: valueNumberFormat(
            values.financingCreditAssessment
          ),
          idadeMaisVelho: Number(values.olderAge),
          prestacaoFinanciamento: valueNumberFormat(values.financingProvision),
          renda: valueNumberFormat(values.income),
          rendaApurada: valueNumberFormat(values.calculatedIncome),
          subsidioAvaliado: valueNumberFormat(values.subsidyAssessed),
          taxaBanco: Number(values.bankFee),
          observacao: values.observations,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingUpdate(false);
          setError(true);
          console.log("status 400", response);
          return;
        }
        if (response.status === 401) {
          setLoadingUpdate(false);
          setIsUnauthorized(true);
          return;
        }

        displayMessage("Cotação editada com sucesso!");
        setSuccessEditQuotes(true);
        setLoadingUpdate(false);
        setEdit(false);
        handleGetQuotesById();
      } catch (error) {
        setLoadingUpdate(false);
        console.log("ErrorHandleUpdateQuote", error);
      }
    },
  });

  const handleGetQuotesById = async () => {
    setLoadingQuotes(true);
    try {
      const response = await quotesService.getQuotesById({
        IdCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingQuotes(false);
        setError(true);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingQuotes(false);
        setIsUnauthorized(true);
        return;
      }

      setValorFinalKit(response?.valorFinalKit);
      setValorTotalUnidade(response?.valorTotalUnidade);
      setBonusAdimplencia(response?.bonusAdimplencia);
      setPaymentCondition(response?.nomeCondicaoPagamentoMatrizSelecionada);
      setDataProposalAnalysis(
        response?.mensagensProposta ? response?.mensagensProposta : []
      );
      setMaxQuantity(
        response?.maximoParcelasCondicaoPagamentoMatrizSelecionada
      );
      setDataQuotes(response);

      if (response.idAvaliacaoCreditoSalesforce !== null) {
        const creditRating =
          await oportunityServiceInstance.getCreditRatingById({
            IdAvaliacaoCreditoSalesforce: response.idAvaliacaoCreditoSalesforce,
          });

        if (creditRating.nome !== undefined) {
          setSelectCreditRating(creditRating.nome);
          setCreditRating(creditRating.nome);
          setSelectCreditRatingId(response.idAvaliacaoCreditoSalesforce);
        }
      } else {
        setSelectCreditRating("");
        setCreditRating("");
        setSelectCreditRatingId("");
      }
      setLoadingQuotes(false);
    } catch (error) {
      setLoadingQuotes(false);
      console.log("ErrorHandleGetCreditRatingById", error);
    }
  };

  const handleGetCreditRatings = async () => {
    setLoadingCreditRating(true);
    try {
      const response = await oportunityServiceInstance.getCreditRating({
        OpportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        somenteAprovadas: true,
      });

      if (response.status === 401) {
        setLoadingCreditRating(false);
        return;
      }

      setDataCreditRating(response ? response : []);
      setLoadingCreditRating(false);
    } catch (error) {
      setLoadingCreditRating(false);
      console.log("ErrorHandleGetCreditRating", error);
    }
  };

  const handleSendToApproval = async () => {
    setLoadingApproval(true);
    setError(false);
    try {
      const response = await quotesService.sendToApproval({
        idCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingApproval(false);
        setError(true);
        setErrorMessage(response?.data?.mensagem);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingApproval(false);
        setIsUnauthorized(true);
        return;
      }

      setIsUpdateAdressIncome(false);
      setIsUpdateAdress(false);

      setIsSendToApproval(true);
      setLoadingApproval(false);
      setSuccessUpdateAddresModal(false);
      setShowModalUpdateAdress(false);
      setShowModalUpdateDate(false);
      displayMessage("Cotação enviada para aprovação!");
    } catch (error) {
      setLoadingApproval(false);
      console.log("ErrorHandleGetCreditRatingById", error);
    }
  };

  const handleCancelButton = () => {
    setEdit(false);
    setIsEdit(false);
    formik.resetForm(initialValues);
    setSelectCreditRating("");
    setSelectCreditRatingId("");
  };

  const isAssociative =
    paymentCondition?.toLowerCase()?.includes("associativ") ||
    paymentCondition?.toLowerCase()?.includes("sfh");

  const disabledIncome = formik.getFieldProps("income").value === "R$ 0,00";
  const disabledFinancingProvision =
    formik.getFieldProps("financingProvision").value === "R$ 0,00";

  React.useEffect(() => {
    if (isEdit) {
      setEdit(true);
    }
  }, [isEdit]);

  React.useEffect(() => {
    if (
      hashDetailOportunity &&
      (hash === "ViewQuotesComponent" ||
        successEditQuotes ||
        parcelamentoSalvo ||
        successCreateMensalPs)
    ) {
      handleGetQuotesById();
    }
  }, [
    hash,
    successEditQuotes,
    parcelamentoSalvo,
    successCreateMensalPs,
    hashDetailOportunity,
  ]);

  if (loading || loadingQuotes) {
    return (
      <Styled.LoadingContainer>
        <Spinner />
      </Styled.LoadingContainer>
    );
  }

  return (
    <>
      <MessageToast
        onOpen={error}
        message={errorMessage || "Erro ao carregar os dados da Cotação!"}
      />

      <AlertCompareValue
        onOpen={compareValue}
        value1={formatMoney(valorFinalKit)}
        value2={formatMoney(valorTotalUnidade)}
        titleButton={"Continuar mesmo assim"}
        onClick={() => {
          setShowModalUpdateDate(true);
          setCompareValue(false);
        }}
        onClose={() => setCompareValue(false)}
      />

      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Modal
        onOpen={showModalUpdateDate}
        title="Atualizar dados"
        subtitle="Preencha as informações para registro e acompanhamento no sistema."
        onClose={() => setShowModalUpdateDate(false)}
      >
        <UpdateDataClientComponent
          onBack={() => setShowModalUpdateDate(false)}
          onSuccess={handleSendToApproval}
          loadindSuccess={loadingApproval}
        />
      </Modal>

      {/* <Modal
        onOpen={showModalUpdateAdress}
        title="Atualizar endereço"
        subtitle="Preencha o endereço do comprador principal e dos compositores de renda"
        onClose={() => setShowModalUpdateAdress(false)}
      >
        <UpdateAdressComponent
          onBack={() => {
            setShowModalUpdateAdress(false);
            setShowModalUpdateDate(true);
          }}
          onSuccess={handleSendToApproval}
          loadindSuccess={loadingApproval}
        />
      </Modal> */}

      <Modal
        onOpen={showCreateMensalPs}
        title="Criar Mensais PS"
        subtitle="Preencha as informações da parcela para adicionar na condição de pagamento."
        onClose={() => setShowCreateMensalPs(false)}
      >
        <CreateMensaPsComponent
          onBack={() => setShowCreateMensalPs(false)}
          setSuccessCreateMensalPs={setSuccessCreateMensalPs}
          maxQuantity={maxQuantity}
        />
      </Modal>

      <Modal
        onOpen={openCharts}
        title="Gráficos"
        subtitle=""
        onClose={() => setOpenCharts(false)}
      >
        <ViewChartsComponent />
      </Modal>

      <Styled.ContainerTitlePage>
        <Styled.ContainerTitle>
          <MdChecklist />
          <Styled.TitlePage>{accessQuote.nomeDaCotacao}</Styled.TitlePage>
        </Styled.ContainerTitle>

        <Styled.ContainerAnyButtons>
          <Styled.ContainerButtonUpdateHeader>
            <Button
              title={
                <Styled.UpdateTitleButtons>
                  <RxUpdate />
                </Styled.UpdateTitleButtons>
              }
              onClick={async () => {
                try {
                  setLoading(true);
                  await handleGetCreditRatings();
                  await handleGetQuotesById();
                  setLoading(false);
                } catch {
                  setLoading(false);
                }
              }}
            />
          </Styled.ContainerButtonUpdateHeader>

          <Styled.ContainerButtonEditHeader>
            <Button
              title={
                <Styled.TitleButtons>
                  <MdOutlineEditNote />
                  EDITAR
                </Styled.TitleButtons>
              }
              onClick={() => setEdit(true)}
              disabled={
                edit ||
                canEdit ||
                faseOp ||
                isSendToApproval ||
                disableEdit ||
                disableApprove
              }
            />
          </Styled.ContainerButtonEditHeader>

          <Button
            title={
              loadingApproval ? (
                <Spinner />
              ) : (
                <Styled.TitleButtons>
                  <BiSend />
                  ENVIAR PARA APROVAÇÃO
                </Styled.TitleButtons>
              )
            }
            onClick={() => {
              if (valorFinalKit > valorTotalUnidade) {
                setCompareValue(true);
              } else {
                setShowModalUpdateDate(true);
              }
            }}
            // onClick={() => setShowModalUpdateDate(true)}
            disabled={
              loadingApproval ||
              !emptyPortion ||
              isSendToApproval ||
              disabledQuote
            }
          />
        </Styled.ContainerAnyButtons>
      </Styled.ContainerTitlePage>

      <Styled.ContainerActivityCreditRating>
        <Styled.ContainerDetail>
          <Styled.ContainerAccordion>
            <AccordionQuotesComponent
              formik={formik}
              edit={edit}
              creditRating={creditRating}
              setCreditRating={setCreditRating}
              selectCreditRating={selectCreditRating}
              selectCreditRatingId={selectCreditRatingId}
              setSelectCreditRating={setSelectCreditRating}
              loadingCreditRating={loadingCreditRating}
              getCreditRating={handleGetCreditRatings}
              dataCreditRating={dataCreditRating}
              setSelectCreditRatingId={setSelectCreditRatingId}
              disabledIncome={disabledIncome}
              bonusAdimplencia={bonusAdimplencia}
            />
          </Styled.ContainerAccordion>
        </Styled.ContainerDetail>

        {edit ? (
          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooterEdit>
              <Button
                error
                title="CANCELAR"
                onClick={handleCancelButton}
                disabled={!edit}
              />

              <Button
                title={loadingUpdate ? <Spinner /> : "SALVAR"}
                onClick={formik.handleSubmit}
                disabled={loadingUpdate || !edit}
              />
            </Styled.ContainerButtonFooterEdit>
          </Styled.ContainerFooterBox>
        ) : (
          <>
            <Styled.ContainerQuotes>
              <Styled.ContainerHeaderQuotes>
                <Styled.ContainerTitleRelationship>
                  <span>Condição de pagamento</span>
                </Styled.ContainerTitleRelationship>
                {/* <Styled.ButtonTitleRelationship>
                  <Button
                    title={
                      <Styled.TitleButtons>
                        <BiPlus />
                        GRÁFICOS
                      </Styled.TitleButtons>
                    }
                    onClick={() => {
                      setOpenCharts(true);
                    }}
                  />
                </Styled.ButtonTitleRelationship> */}
                {isAssociative && (
                  <Styled.ButtonTitleRelationship>
                    <Button
                      title={
                        <Styled.TitleButtons>
                          <BiPlus />
                          MENSAL PS
                        </Styled.TitleButtons>
                      }
                      onClick={() => {
                        if (disabledFinancingProvision) {
                          displayMessageError({
                            message: `Campo "Prestação Financiamento" obrigatório! Preencha no menu Valores.`,
                          });
                        } else {
                          setShowCreateMensalPs(true);
                        }
                      }}
                      disabled={
                        existMensalPs ||
                        isSendToApproval ||
                        disableEdit ||
                        disableApprove ||
                        !parcelamentoSalvo ||
                        parcelaAdicionadaNaoSalva
                      }
                    />
                  </Styled.ButtonTitleRelationship>
                )}
              </Styled.ContainerHeaderQuotes>

              <Styled.ContainerTableQuotes>
                <TablePortion
                  data={dataQuotes}
                  discount={discount}
                  setDiscount={setDiscount}
                  successCreateMensalPs={successCreateMensalPs}
                  setExistMensalPs={setExistMensalPs}
                  setEmptyPortion={setEmptyPortion}
                  disabledIncome={disabledIncome}
                  disableEdit={disableEdit}
                  disabledStatusApprove={disableApprove}
                  onSalvarParcelamento={handleSalvarParcelamento}
                  onAdicionarParcela={handleAdicionarParcela}
                  bonusAdimplencia={bonusAdimplencia}
                />
              </Styled.ContainerTableQuotes>
            </Styled.ContainerQuotes>

            <Styled.ContainerQuotes>
              <Styled.ContainerHeaderQuotes>
                <Styled.ContainerTitleRelationship>
                  <span>Análise da Proposta</span>
                </Styled.ContainerTitleRelationship>
              </Styled.ContainerHeaderQuotes>

              <Styled.ContainerTableQuotes>
                <TableProposalAnalysis
                  dataProposalAnalysis={dataProposalAnalysis}
                />
              </Styled.ContainerTableQuotes>
            </Styled.ContainerQuotes>
          </>
        )}
      </Styled.ContainerActivityCreditRating>
    </>
  );
};

export default ViewQuotesComponent;
