import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Box,
  Text,
  Icon,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { MdClose, MdLogout, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "@context/loginContext";

interface IDrawerSidebar {
  isOpen: boolean;
  widthMd?: boolean;
  onClose: () => void;
}

const DrawerUser = ({ isOpen, onClose, widthMd }: IDrawerSidebar) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { signOut, setPageSidebar } = React.useContext(LoginContext);

  const handleSignOut = (e: any) => {
    e.stopPropagation();
    signOut();
    toast.closeAll();
  };

  return (
    <Box display={"flex"} pos={"absolute"}>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        {/* <DrawerOverlay /> */}
        <DrawerContent marginLeft={widthMd ? 0 : 230}>
          <DrawerBody>
            <Flex
              display={"flex"}
              pos={"fixed"}
              top={0}
              left={0}
              flexDir={"column"}
              h={"100%"}
              background={"white"}
              zIndex={999}
              p={4}
              overflowY={"auto"}
            >
              <Flex justifyContent={"space-around"} alignItems={"center"}>
                <Text fontWeight={"bold"}>Minha conta</Text>

                <Box
                  display={"flex"}
                  w={"fit-content"}
                  borderWidth={1}
                  borderColor={"transparent"}
                  fontSize="22px"
                  color={"gray"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  alignSelf={"center"}
                  textAlign={"center"}
                  cursor={"pointer"}
                  onClick={onClose}
                  _hover={{
                    borderWidth: 1,
                    borderColor: theme.text,
                    transitionDuration: "0.2s",
                    borderRadius: "8px",
                    color: theme.text,
                  }}
                >
                  <MdClose />
                </Box>
              </Flex>

              <Flex
                mt={8}
                h={"50px"}
                flexDir={"column"}
                justifyContent={"center"}
              >
                <Box
                  w={"fit-content"}
                  h={"fit-content"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                  p={4}
                  cursor={"pointer"}
                  _hover={{
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    navigate("/myProfile");
                    setPageSidebar("Profile");
                  }}
                >
                  <Icon fontSize="22px" color={theme.text} as={MdOutlineEdit}  />

                  <Text fontSize={"14px"} fontWeight={"medium"}>
                    Editar perfil
                  </Text>
                </Box>
              </Flex>

              <Flex p={2}>
                <Divider borderColor={theme.disable} />
              </Flex>

              <Flex
                mt={1}
                h={"50px"}
                flexDir={"column"}
                justifyContent={"center"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                  p={4}
                  cursor={"pointer"}
                  _hover={{
                    textDecoration: "underline",
                  }}
                  onClick={(e) => handleSignOut(e)}
                >
                  <Icon fontSize="22px" color={theme.text} as={MdLogout} />

                  <Text fontSize={"14px"} fontWeight={"medium"}>
                    Sair da conta
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default DrawerUser;
