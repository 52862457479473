/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import * as Styled from "./styles";
import { LoginContext } from "@context/loginContext";

type Props = {};

const Commission = (props: Props) => {
  const TOKEN = sessionStorage.getItem("@token");
  const { openSidebar, setPageSidebar } = React.useContext(LoginContext);
  const EMBED_URL = "https://app.splitc.com.br/sso";

  function CLIENT_ID() {
    if (window.location.href.includes("localhost"))
      return "428ece19-8162-484d-ba48-d2141be4ab55";

    if (window.location.href.includes("dev"))
      return "428ece19-8162-484d-ba48-d2141be4ab55";

    if (window.location.href.includes("qa"))
      return "b0b9a67d-459b-4ec5-afff-522ed1ebcccc";

    if (window.location.href.includes("stage"))
      return "233acc08-40dc-43e9-819b-a114310f714f";

    return "aee08639-7e23-4e82-8412-55bcb23da7d1";
  }

  return (
    <div>
      <Styled.Area open={openSidebar}>
        <Styled.Container>
          <Styled.SubContainer>
            <Styled.ContainerTop>
              {/* <BreadCrumb items={breadcrumbItems} /> */}
            </Styled.ContainerTop>
            <iframe
              src={`${EMBED_URL}?clientId=${CLIENT_ID()}&token=${TOKEN}&hide_menu=true`}
              style={{ width: "100%", height: "95vh" }}
              // frameborder="0"
              allowFullScreen
            />
          </Styled.SubContainer>
        </Styled.Container>
      </Styled.Area>
    </div>
  );
};

export default Commission;
