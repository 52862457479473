import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Flex,
  Box,
  Image,
  Icon,
  Text,
  Divider,
} from "@chakra-ui/react";
import logo from "../../../assets/logo.svg";
import logoDirecional from "../../../assets/LogoDirecional.png";
import logoRiva from "../../../assets/Rivalogo.png";
import * as Styled from "./styles";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@helpers/format";
import { LoginContext } from "@context/loginContext";
import { linkSidebar } from "@helpers/linkSidebar";
import { UsuallyContext } from "@context/usuallyContex";
import { theme } from "src/styles/theme";
import { BiBell, BiUser } from "react-icons/bi";
import { SlOptionsVertical } from "react-icons/sl";
import DrawerNotification from "../DrawerNotification";
import DrawerUser from "../DrawerUser";
import notifyServiceInstance from "@services/Notify/notifyService";

interface IDrawerSidebar {
  selectedOption: string;
  setSelectedOption: any;
}

type SubItem = { name: string };

const DrawerSidebar = ({
  selectedOption,
  setSelectedOption,
}: IDrawerSidebar) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { signOut } = React.useContext(LoginContext);
  const { setHashSalesSidebar } = React.useContext(UsuallyContext);
  const user: any = localStorage.getItem("@user");
  const email: any = localStorage.getItem("@email");
  const [openNotification, setOpenNotification] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);

  const [notifications, setNotifications] = React.useState<any[]>([]);
  const [isMarked, setIsMarked] = React.useState(true);

  const handleOptionClick = (label: string) => {
    setSelectedOption(label);
    localStorage.setItem("@option", label);
  };

  function handleNavigateSubMenu(name: string) {
    if (name.toLowerCase() === "buscar clientes")
      return setHashSalesSidebar("Oportunity");
    if (name.toLowerCase() === "minhas oportunidades")
      return setHashSalesSidebar("MyOportunityComponent");
    return setHashSalesSidebar("");
  }

  const handleNavigateMenu = (label: string, to: string) => {
    if (label === "Notificações") {
      setOpenNotification(true); // Abre o drawer de notificações
    } else {
      handleOptionClick(label);
      navigate(to);
      handleNavigateSubMenu(label);
    }
  };

  const handleMarkDisplayed = async () => {
    const idsNotificacaoNovaOp = notifications
      .filter((notification) => notification.tipoNotificacao === "NovaOP")
      .map((notification) => notification.id);

    const idsNotificacaoRetornoAC = notifications
      .filter((notification) => notification.tipoNotificacao === "RetornoAC")
      .map((notification) => notification.id);

    const idsNotificacaoRetornoCotacao = notifications
      .filter(
        (notification) => notification.tipoNotificacao === "RetornoCotacao"
      )
      .map((notification) => notification.id);

    const data = {
      idsNotificacaoNovaOp,
      idsNotificacaoRetornoAC,
      idsNotificacaoRetornoCotacao,
    };

    try {
      const response = await notifyServiceInstance.markDisplayed(data);

      if (response?.status >= 400 && response?.status !== 401) {
        console.log("Erro ao marcar notificações como exibidas:", response);
        return;
      }

      if (response?.status === 401) {
        console.log("Usuário não autorizado:", response);
        setIsMarked(false);
        return;
      }
    } catch (error) {
      console.error("Erro ao marcar notificações como exibidas:", error);
    }
  };

  return (
    <>
      <DrawerNotification
        isOpen={openNotification}
        onClose={() => setOpenNotification(false)}
        widthMd
        notifications={notifications}
        setNotifications={setNotifications}
        setIsMarked={setIsMarked}
      />

      <DrawerUser
        isOpen={openUser}
        onClose={() => setOpenUser(false)}
        widthMd
      />

      <Styled.Container>
        <Styled.IconRxHamburgerMenu onClick={onOpen} color="black" />

        <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody>
              <Flex
                display={"flex"}
                pos={"fixed"}
                top={0}
                left={0}
                flexDir={"column"}
                h={"100%"}
                background={theme.primary700}
                zIndex={998}
                transition={"0.2s"}
              >
                <Flex display={"flex"} flexDir={"column"} w={"100%"} h={"100%"}>
                  <Flex
                    p={"2px"}
                    justifyContent="space-around"
                    alignItems="center"
                    mt={5}
                  >
                    {/* Logo */}
                    <Box
                      bg="gray.600"
                      display={"flex"}
                      w={"fit-content"}
                      p={1}
                      borderRadius={"8px"}
                    >
                      <Image
                        src={logo}
                        alt="Logo"
                        // onClick={handleClickImg}
                        cursor="pointer"
                        w={"140px"}
                      />
                    </Box>

                    {/* Ícone de Sino com Bolinha Vermelha e Animação */}
                    <Box
                      position="relative"
                      w={"fit-content"}
                      h={"fit-content"}
                      onClick={() => {
                        setOpenNotification(true);
                        handleMarkDisplayed();
                      }}
                    >
                      <Icon
                        cursor={"pointer"}
                        fontSize="22px"
                        color="white"
                        as={BiBell}
                        _hover={{ animation: "shake 0.5s" }}
                      />
                      {/* Bolinha Vermelha */}
                      {isMarked === false && (
                        <Box
                          position="absolute"
                          top="-3px"
                          right="-3px"
                          bg={theme.error}
                          w="8px"
                          h="8px"
                          borderRadius="full"
                        ></Box>
                      )}
                    </Box>

                    {/* Keyframes da Animação */}
                    <style>
                      {`
          @keyframes shake {
            0% { transform: rotate(0deg); }
            25% { transform: rotate(-10deg); }
            50% { transform: rotate(10deg); }
            75% { transform: rotate(-10deg); }
            100% { transform: rotate(0deg); }
          }
        `}
                    </style>
                  </Flex>

                  {/* Meu perfil */}
                  <Flex
                    mt={8}
                    p={1}
                    justifyContent={"space-around"}
                    cursor={"pointer"}
                    onClick={() => setOpenUser(true)}
                  >
                    <Box
                      display={"flex"}
                      bg={theme.disable100}
                      w={"35px"}
                      h={"35px"}
                      borderRadius={"full"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <BiUser />
                    </Box>

                    <Box
                      display={"flex"}
                      flexDir={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      w={"fit-content"}
                    >
                      <Text fontSize={"11px"} color={"white"}>
                        {capitalize(user)}
                      </Text>
                      <Text fontSize={"10px"} color={"white"}>
                        {email}
                      </Text>
                    </Box>

                    <Box
                      display={"flex"}
                      flexDir={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      w={"fit-content"}
                    >
                      <Icon fontSize="18px" color={"white"}>
                        <SlOptionsVertical />
                      </Icon>
                    </Box>
                  </Flex>

                  <Flex p={2} mt={5} mb={8}>
                    <Divider borderColor={theme.disable} />
                  </Flex>

                  {/* Options */}
                  <Box display={"flex"} flexDir={"column"} gap={3}>
                    {linkSidebar.map(({ icon, label, to }) => (
                      <Flex
                        key={label}
                        flexDir={"column"}
                        w={"97%"}
                        h={"fit-content"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          display={"flex"}
                          w={"95%"}
                          h={"45px"}
                          pl={"8px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          borderRadius={"8px"}
                          gap={2}
                          cursor={"pointer"}
                          bg={
                            selectedOption === label
                              ? theme.backgroundPrimary
                              : "transparent"
                          }
                          _hover={{
                            bg: theme.backgroundGray,
                          }}
                          onClick={() => {
                            handleNavigateMenu(label, to);
                          }}
                        >
                          <Icon
                            fontSize="18px"
                            color={"white"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            {icon}
                          </Icon>

                          <Text
                            color={"white"}
                            fontSize={"15px"}
                            fontWeight={
                              selectedOption === label ? "bold" : "normal"
                            }
                          >
                            {label}
                          </Text>
                        </Box>
                      </Flex>
                    ))}
                  </Box>

                  {/* Logos Footer */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                    h={"100%"}
                    mb={15}
                  >
                    {/* <Styled.ContainerAnyLogos> */}
                    <Styled.ContainerLogo>
                      <Styled.ContainerBackgroundAnyLogos>
                        <Styled.ImgLarge
                          alt="logo"
                          src={logoDirecional}
                          style={{ height: "12px" }}
                        />
                      </Styled.ContainerBackgroundAnyLogos>
                    </Styled.ContainerLogo>

                    <Styled.ContainerLogo>
                      <Styled.ContainerBackgroundAnyLogos>
                        <Styled.ImgLarge
                          alt="logo"
                          src={logoRiva}
                          style={{ height: "20px" }}
                        />
                      </Styled.ContainerBackgroundAnyLogos>
                    </Styled.ContainerLogo>
                    {/* </Styled.ContainerAnyLogos> */}
                  </Flex>
                </Flex>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Styled.Container>
    </>
  );
};

export default DrawerSidebar;
