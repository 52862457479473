import React from "react";
import * as Styled from "../stylesView&Create";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import oportunityService from "@services/Oportunity/OportunityService";
import InputText from "@components/Sales/Inputs/InputText";
import { MdOutlineEditNote } from "react-icons/md";
import { OportunityContext } from "@context/oportunityContex";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { useMessageToast } from "@hook/useMessageToast";
import { CloseButton, useToast } from "@chakra-ui/react";
import { IViewScheduleComponent } from "src/@types/Sales";

const ViewScheduleComponent = ({
  onBack,
  idAgendamentoSalesforce,
  isEdit = false,
  setIsEdit,
  setShowDetailSchedule,
  setDeleteSchedule,
}: IViewScheduleComponent) => {
  const toast = useToast();
  const displayMessageError = useMessageErrorToast();
  const displayMessage = useMessageToast();
  const nameUser: any = localStorage.getItem("@user");
  const { faseOp } = React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [observations, setObservations] = React.useState("");
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [loadingSchedule, setLoadingSchedule] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [edit, setEdit] = React.useState(false);

  const handleCancelButton = () => {
    setEdit(false);
    setIsEdit(false);
    setObservations(data?.observacoes);
  };

  const displayMessageDelete = ({ message }: { message: string }) => {
    toast.closeAll();
    toast({
      position: "top-right",
      status: undefined,
      duration: 6000,
      render: () => (
        <Styled.ContainerToast>
          <Styled.ContainerHeaderToast>
            <span>Bora Vender</span>
            <CloseButton onClick={() => toast.closeAll()} />
          </Styled.ContainerHeaderToast>

          <Styled.ContainerMessageToast>
            <span>{message}</span>
          </Styled.ContainerMessageToast>
        </Styled.ContainerToast>
      ),
      containerStyle: {
        maxWidth: "350px",
        marginTop: "5px",
        boxShadow: "dark-lg",
        borderRadius: "8px",
        bg: "white",
      },
    });
  };

  const handleUpdateSchedule = async () => {
    setLoading(true);

    try {
      const response = await oportunityService.updateSchedule({
        idAgendamentoSalesforce,
        observacoes: observations,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({ message: "Erro ao atualizar agendamento!" });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      displayMessage({
        title: "Agendamento editado com sucesso!",
        message:
          "Suas alterações foram salvas e você já pode continuar sua venda.",
        titleButton: "ACESSAR AGENDAMENTO",
        onClick: () => setShowDetailSchedule(true),
      });

      setLoading(false);
      setIsEdit(false);
      onBack();
    } catch (error) {
      setLoading(false);
      console.log("errorHandleUpdateTaskCall", error);
    }
  };

  const handleDeleteSchedule = async () => {
    setLoadingDelete(true);
    setDeleteSchedule(false);
    try {
      const response = await oportunityService.deleteSchedule(
        idAgendamentoSalesforce
      );

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingDelete(false);
        displayMessageError({
          message: response.data
            ? response.data
            : "Erro ao deletar agendamento!",
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingDelete(false);
        setIsUnauthorized(true);
        return;
      }

      displayMessageDelete({ message: "Agendamento deletado com sucesso!" });
      setLoadingDelete(false);
      setDeleteSchedule(true);
      onBack();
    } catch (error) {
      setLoadingDelete(false);
      console.log("errorHandleDeleteSchedule", error);
    }
  };

  React.useEffect(() => {
    setEdit(isEdit);
  }, [isEdit]);

  React.useEffect(() => {
    const handleGetScheduleById = async () => {
      if (!idAgendamentoSalesforce) return;

      setLoadingSchedule(true);

      try {
        const response = await oportunityService.getScheduleById({
          idAgendamentoSalesforce,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingSchedule(false);
          setData([]);
          console.log("status 400", response);
          return;
        }
        if (response.status === 401) {
          setLoadingSchedule(false);
          setIsUnauthorized(true);
          setData([]);
          return;
        }

        setObservations(response?.observacoes);
        setData(response);
        setLoadingSchedule(false);
      } catch (error) {
        setLoadingSchedule(false);
        console.log("errorHandleGetScheduleById", error);
      }
    };

    handleGetScheduleById();
  }, [idAgendamentoSalesforce]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {loadingSchedule ? (
        <Styled.ContainerLoadingView>
          <Spinner />
        </Styled.ContainerLoadingView>
      ) : (
        <>
          <Styled.AreaSchedule>
            <Styled.ContainerSchedule>
              <Styled.TitleSchedule>
                Detalhes do agendamento
              </Styled.TitleSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="Data do agendamento:"
                  name="Data do agendamento"
                  value={data?.dataAgendamento}
                />
                <InputText
                  readOnly
                  label="Hora do agendamento:"
                  name="Hora do agendamento"
                  value={data.horaAgendamento}
                />
                <InputText
                  readOnly
                  label="Tipo Visita:"
                  name="Tipo Visita"
                  value={data?.tipoDeVisita}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="PDV:"
                  name="PDV"
                  value={data?.nomePDV}
                />
                <InputText
                  readOnly
                  label="Empreendimento de interesse:"
                  name="Empreendimento de interesse"
                  value={data?.nomeEmpreendimento}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="Código do agendamento:"
                  name="Código do agendamento"
                  value={data?.codigoDoAgendamento}
                />
                <InputText
                  readOnly
                  tooltip={!!data?.localizacaoDoStand}
                  label="Localização do stand:"
                  name="Localização do stand"
                  value={data?.localizacaoDoStand}
                />
                <InputText
                  readOnly
                  label="Unidade do negócio:"
                  name="Unidade do negócio"
                  value={data?.unidadeDeNegocio}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="Regional:"
                  name="Regional"
                  value={data?.regional}
                />
                <InputText
                  readOnly
                  tooltip={data?.assunto?.length >= 25}
                  label="Assunto:"
                  name="Assunto"
                  value={data?.assunto}
                />
                <InputText
                  readOnly
                  tooltip={!!data?.participantes}
                  label="Participantes:"
                  name="Participantes"
                  value={data?.participantes}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                <InputTextArea
                  readOnly={!edit}
                  label="Observações:"
                  placeholder=""
                  name="Observações"
                  value={observations}
                  onChange={(e) => setObservations(e.target.value)}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerButtonEdit>
                <Styled.AreaButtonEdit>
                  <Button
                    title={
                      <Styled.TitleButtons>
                        <MdOutlineEditNote />
                        EDITAR
                      </Styled.TitleButtons>
                    }
                    disabled={edit || faseOp}
                    onClick={() => setEdit(true)}
                  />
                </Styled.AreaButtonEdit>
              </Styled.ContainerButtonEdit>
            </Styled.ContainerSchedule>

            <Styled.ContainerSchedule>
              <Styled.TitleSchedule>Validação da recepção</Styled.TitleSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="Visita realizada:"
                  name="Visita realizada"
                  value={data?.visitaRealizada === true ? "Sim" : "Não"}
                />
                <InputText
                  readOnly
                  label="Data de comparecimento:"
                  name="Data de comparecimento"
                  value={data?.dataDeComparecimento}
                />
              </Styled.ContainerInputsSchedule>
            </Styled.ContainerSchedule>

            <Styled.ContainerSchedule>
              <Styled.TitleSchedule>
                Informações do consultor
              </Styled.TitleSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="Imobiliária:"
                  name="Imobiliária"
                  value={data?.imobiliaria}
                />
                <InputText
                  readOnly
                  label="Consultor:"
                  name="Consultor"
                  value={nameUser}
                />
                <InputText
                  readOnly
                  label="Gerente de vendas:"
                  name="Gerente de vendas"
                  value={data?.gerenteDeVendas}
                />
              </Styled.ContainerInputsSchedule>

              <Styled.ContainerInputsSchedule>
                <InputText
                  readOnly
                  label="Gerente regional:"
                  name="Gerente regional"
                  value={data?.gerenteRegional}
                />
                <InputText
                  readOnly
                  label="Diretor de vendas:"
                  name="Diretor de vendas"
                  value={data?.diretorDeVendas}
                />
              </Styled.ContainerInputsSchedule>
            </Styled.ContainerSchedule>
          </Styled.AreaSchedule>

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooterEdit>
              <Button
                error
                title="CANCELAR"
                onClick={handleCancelButton}
                disabled={!edit || faseOp}
              />

              <Button
                title={loading ? <Spinner /> : "SALVAR"}
                onClick={handleUpdateSchedule}
                disabled={loading || !edit || faseOp}
              />
            </Styled.ContainerButtonFooterEdit>
          </Styled.ContainerFooterBox>
        </>
      )}
    </>
  );
};

export default ViewScheduleComponent;
