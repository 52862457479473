interface IIcon {
  width: string;
  height: string;
}

export const IconLupaError = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 221 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="109.518" cy="116.876" r="68.9464" fill="#FCEFEF" />
    <circle cx="44.8926" cy="70.3223" r="4.32143" fill="#FCEFEF" />
    <circle cx="158.625" cy="42.233" r="8.05357" fill="#FCEFEF" />
    <circle cx="46.2676" cy="161.269" r="7.66071" fill="#FCEFEF" />
    <circle cx="183.375" cy="153.804" r="7.66071" fill="#FCEFEF" />
    <circle cx="168.446" cy="162.447" r="3.73214" fill="#FCEFEF" />
    <path
      d="M186.973 130.166L188.176 135.85L193.778 137.07L188.176 138.291L186.973 143.974L185.77 138.291L180.167 137.07L185.77 135.85L186.973 130.166Z"
      fill="white"
    />
    <path
      d="M179.778 72.138C179.866 71.7227 180.459 71.7227 180.547 72.138L181.313 75.7574C181.345 75.9086 181.463 76.027 181.614 76.0599L185.206 76.8425C185.618 76.9323 185.618 77.5204 185.206 77.6102L181.614 78.3928C181.463 78.4257 181.345 78.5441 181.313 78.6953L180.547 82.3147C180.459 82.73 179.866 82.73 179.778 82.3147L179.012 78.6953C178.98 78.5441 178.862 78.4257 178.711 78.3928L175.119 77.6102C174.707 77.5204 174.707 76.9323 175.119 76.8425L178.711 76.0599C178.862 76.027 178.98 75.9086 179.012 75.7574L179.778 72.138Z"
      fill="#EEA8AA"
    />
    <path
      d="M37.123 119.674C37.2109 119.258 37.8038 119.258 37.8917 119.674L39.2049 125.878C39.2369 126.029 39.3546 126.148 39.5057 126.181L45.6458 127.518C46.0581 127.608 46.0581 128.196 45.6458 128.286L39.5057 129.623C39.3546 129.656 39.2369 129.775 39.2049 129.926L37.8917 136.13C37.8038 136.546 37.2109 136.546 37.123 136.13L35.8098 129.926C35.7778 129.775 35.6601 129.656 35.509 129.623L29.3689 128.286C28.9566 128.196 28.9566 127.608 29.3689 127.518L35.509 126.181C35.6601 126.148 35.7778 126.029 35.8098 125.878L37.123 119.674Z"
      fill="#EEA8AA"
    />
    <path
      d="M61.5989 53.6404C61.3711 53.592 61.3711 53.2669 61.5989 53.2185L65.0426 52.4862C65.1256 52.4685 65.1905 52.4038 65.2085 52.3209L65.9499 48.9015C65.9991 48.6749 66.3224 48.6749 66.3715 48.9015L67.113 52.3209C67.131 52.4038 67.1959 52.4685 67.2789 52.4862L70.7226 53.2185C70.9503 53.2669 70.9503 53.592 70.7226 53.6404L67.2789 54.3727C67.1959 54.3904 67.131 54.455 67.113 54.538L66.3715 57.9574C66.3224 58.184 65.9991 58.184 65.9499 57.9574L65.2085 54.538C65.1905 54.455 65.1256 54.3904 65.0426 54.3727L61.5989 53.6404Z"
      fill="#EEA8AA"
    />
    <circle
      cx="123.857"
      cy="91.5359"
      r="43.6071"
      fill="#F9D7D8"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
    <circle
      cx="123.857"
      cy="91.5359"
      r="36.5357"
      fill="#FCEFEF"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
    <path
      d="M66.9886 172.318L90.5571 139.584L91.6046 138.798L93.9614 138.274H97.3658L99.1989 139.584L101.032 140.369L102.603 142.202L103.913 145.083L83.4866 183.578L81.1297 184.888H78.2491L74.3211 183.578L71.1786 182.269L68.298 178.341L66.9886 176.77V172.318Z"
      fill="#F9D7D8"
    />
    <path
      d="M108.365 132.514L102.39 142.203"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
    <path
      d="M101.342 129.371L95.5806 138.012"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
    <path
      d="M92.1278 138.274L66.7262 173.103"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
    <path
      d="M103.651 144.56L83.2248 184.102"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
    <path
      d="M91.6044 138.798L92.0355 138.558C92.9785 138.033 94.0622 137.816 95.1347 137.937V137.937C95.5727 137.987 96.0025 138.093 96.4137 138.251L97.1517 138.536L97.4006 138.619C98.7869 139.081 100.047 139.86 101.08 140.893V140.893L101.892 141.705C102.222 142.035 102.511 142.405 102.751 142.805V142.805C103.032 143.274 103.244 143.78 103.382 144.309L103.65 145.345"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
    <path
      d="M83.7484 183.578L82.633 184.079C81.5526 184.565 80.3873 184.835 79.2031 184.872V184.872C78.2835 184.901 77.3648 184.789 76.4788 184.541L75.7275 184.331L75.4756 184.246C73.2493 183.49 71.2218 182.243 69.5438 180.596V180.596L68.5737 179.644C67.8153 178.9 67.2468 177.984 66.9163 176.974V176.974C66.5052 175.718 66.4791 174.368 66.8413 173.096L66.9886 172.579"
      stroke="#EEA8AA"
      stroke-width="1.57143"
    />
  </svg>
);
