import React from "react";
import * as Styled from "../stylesStepTab";
import Button from "@components/common/Button";
import { FiEdit, FiPlus } from "react-icons/fi";
import { OportunityContext } from "@context/oportunityContex";
import Spinner from "@components/common/Spinner";
import { MdChecklist, MdOutlineEditNote } from "react-icons/md";
import quotesService from "@services/Quotes/QuotesService";
import { IQuotesComponent } from "src/@types/Sales";

const QuotesComponent = ({
  indexFirstTab,
  setHash,
  setIsEdit,
  setCanEdit,
  successCreateQuote = false,
  setSuccessCreateQuote,
  navigateCreateQuote,
  updateData,
}: IQuotesComponent) => {
  const {
    accessCreateOp,
    setAccessQuote,
    faseOp,
    setPaymentCondition,
    setIsSendToApproval,
    setDisabledQuote,
  } = React.useContext(OportunityContext);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);

  const filteredRows = React.useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data;
  }, [data]);

  const handleFormatStatus = (value: string): any => {
    if (!value) return "";
    if (value?.toLocaleLowerCase().includes("aprovad")) return "Aprovada";
    if (value?.toLocaleLowerCase().includes("rejeitad")) return "Rejeitada";
    if (value?.toLocaleLowerCase().includes("cancelad")) return "Cancelada";
    if (value?.toLocaleLowerCase().includes("lise")) return "Em análise";
    if (value?.toLocaleLowerCase().includes("rascun")) return "Rascunho";
  };

  const handleCanEdit = (status: string) => {
    if (
      handleFormatStatus(status) === "Em análise" ||
      handleFormatStatus(status) === "Aprovada"
    )
      return true;

    return false;
  };

  const disabledQuote = filteredRows.some((row: any) => {
    const formattedStatus = handleFormatStatus(row.status);
    setDisabledQuote(
      formattedStatus === "Aprovada" || formattedStatus === "Em análise"
    );
    return formattedStatus === "Aprovada" || formattedStatus === "Em análise";
  });

  React.useEffect(() => {
    const handleGetQuotes = async () => {
      setLoading(true);
      try {
        const response = await quotesService.getQuotes({
          OpportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        });

        if (response.status === 401) {
          setLoading(false);
          return;
        }

        setData(Array.isArray(response) ? response : []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("ErrorHandleGetQuotes", error);
      }
    };

    if (indexFirstTab === 2) {
      handleGetQuotes();
    }
    if (successCreateQuote) {
      handleGetQuotes();
    }
  }, [
    accessCreateOp.oportunityIdSalesforce,
    indexFirstTab,
    setSuccessCreateQuote,
    successCreateQuote,
    updateData,
  ]);

  return (
    <Styled.ContainerQuotes>
      <Styled.ContainerHeader>
        <Styled.TitleActivity>
          <h1>Cotações da oportunidade</h1>
        </Styled.TitleActivity>

        <Styled.ContaineButtonsHeader>
          <Button
            title={
              <Styled.TitleButon>
                <FiPlus />
                NOVA COTAÇÂO
              </Styled.TitleButon>
            }
            onClick={() => {
              navigateCreateQuote();
            }}
            disabled={faseOp || disabledQuote}
          />
        </Styled.ContaineButtonsHeader>
      </Styled.ContainerHeader>

      {loading ? (
        <Styled.ContainerTodoHistoricLoading>
          <Spinner />
        </Styled.ContainerTodoHistoricLoading>
      ) : (
        <>
          <Styled.ContainerTitleLabelQuote>
            {filteredRows.length === 0 ? (
              <Styled.ContainerTodoHistoricEmpty>
                <span>Não há cotação nesta oportunidade.</span>
              </Styled.ContainerTodoHistoricEmpty>
            ) : (
              <>
                <Styled.TitleLabelQuotesLeft>
                  Nome da cotação:
                </Styled.TitleLabelQuotesLeft>

                <Styled.TitleLabelQuoteRight>
                  Status:
                </Styled.TitleLabelQuoteRight>
                <Styled.TitleLabelQuoteRightDate>
                  Data da proposta:
                </Styled.TitleLabelQuoteRightDate>
              </>
            )}
          </Styled.ContainerTitleLabelQuote>

          <Styled.ContainerTodoHistoric>
            {filteredRows?.map(
              ({
                idCotacaoSalesforce,
                status,
                nomeDaCotacao,
                nomeProduto,
                nomeCondicaoPagamentoMatrizSelecionada,
                dataProposta,
              }) => (
                <Styled.ContainerScheduleSeeSchedule key={idCotacaoSalesforce}>
                  <Styled.ContainerLeftCreditRating>
                    <Styled.ContainerIconHistoricCreditRating
                      status={handleFormatStatus(status)}
                    >
                      {handleFormatStatus(status) === "Rascunho" ? (
                        <FiEdit />
                      ) : (
                        <MdChecklist />
                      )}
                    </Styled.ContainerIconHistoricCreditRating>
                    <Styled.ContainerTextHistoric>
                      <Styled.TitleTextHistoric>
                        {nomeDaCotacao}
                      </Styled.TitleTextHistoric>
                      <Styled.SubTitleTextHistoric>
                        Produto: {nomeProduto} / Condição de pagamento:{" "}
                        {nomeCondicaoPagamentoMatrizSelecionada}
                      </Styled.SubTitleTextHistoric>
                    </Styled.ContainerTextHistoric>
                  </Styled.ContainerLeftCreditRating>

                  <Styled.ContainerDataQuotes>
                    <Styled.ContainerIconTextQuotes>
                      <Styled.ContainerTextDataStatusQuote>
                        <Styled.ContainerIconQuote status={status}>
                          <span>{handleFormatStatus(status)}</span>
                        </Styled.ContainerIconQuote>
                      </Styled.ContainerTextDataStatusQuote>

                      <Styled.BoxDivider>
                        <Styled.DividerActivityCreditRating />
                      </Styled.BoxDivider>

                      <Styled.ContainerTextDataStatusQuote>
                        <Styled.SubTitleTextDataQuote>
                          {dataProposta}
                        </Styled.SubTitleTextDataQuote>
                      </Styled.ContainerTextDataStatusQuote>

                      <Styled.BoxDivider>
                        <Styled.DividerActivityCreditRating />
                      </Styled.BoxDivider>

                      <Styled.ContainerButtonDataStatus>
                        <Styled.ContainerButtonIconDataStatus>
                          <Styled.AreaButtonIconDataTasksCalls
                            faseOp={handleCanEdit(status) || faseOp}
                            onClick={() => {
                              if (handleCanEdit(status)) {
                              } else {
                                setPaymentCondition(
                                  nomeCondicaoPagamentoMatrizSelecionada
                                );
                                setHash("ViewQuotesComponent");
                                setIsEdit(true);
                                setAccessQuote({
                                  idCotacaoSalesforce,
                                  nomeDaCotacao,
                                });
                              }
                            }}
                          >
                            <span>Editar</span>
                            <MdOutlineEditNote />
                          </Styled.AreaButtonIconDataTasksCalls>
                        </Styled.ContainerButtonIconDataStatus>

                        <Button
                          title="VISUALIZAR"
                          onClick={() => {
                            setHash("ViewQuotesComponent");
                            setPaymentCondition(
                              nomeCondicaoPagamentoMatrizSelecionada
                            );
                            setIsEdit(false);
                            setCanEdit(handleCanEdit(status));
                            setAccessQuote({
                              idCotacaoSalesforce,
                              nomeDaCotacao,
                            });

                            setIsSendToApproval(handleCanEdit(status));
                          }}
                        />
                      </Styled.ContainerButtonDataStatus>
                    </Styled.ContainerIconTextQuotes>
                  </Styled.ContainerDataQuotes>
                </Styled.ContainerScheduleSeeSchedule>
              )
            )}
          </Styled.ContainerTodoHistoric>
        </>
      )}
    </Styled.ContainerQuotes>
  );
};

export default QuotesComponent;
