import React from "react";
import * as Styled from "./styles";
import DrawerSidebar from "../DrawerSidebar";
import { Flex } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { BiUser } from "react-icons/bi";

interface IMenu {
  isOpen: boolean;
  onClickProfile?: any;
  isSelected?: boolean;
  selectedOption: string;
  setSelectedOption: any;
}

const Menu = ({
  isOpen,
  onClickProfile,
  isSelected,
  selectedOption,
  setSelectedOption,
}: IMenu) => {
  return (
    // <Styled.Container isOpen={isOpen}>
    <Flex
      display={"flex"}
      pos={"fixed"}
      w={"100%"}
      h={"50px"}
      background={theme.disable100}
      zIndex={-999}
      transition={"0.2s"}
    >
      <Flex
        w={"90%"}
        h={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        pl={"18px"}
      >
        <DrawerSidebar
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </Flex>

      {/* <Flex
        display={"flex"}
        w={"200px"}
        h={"100%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Styled.ContainerOtions>
          <Styled.Otions onClick={onClickProfile} isSelected={isSelected}>
            <BiUser size="20px" />
          </Styled.Otions>
        </Styled.ContainerOtions>
      </Flex> */}
    </Flex>
    // </Styled.Container>
  );
};

export default Menu;
