import { createGlobalStyle } from "styled-components";

import { css } from "styled-components";

const RobotoFont = css`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
`;

export const GlobalStyle = createGlobalStyle`
     *, *::before, *::after {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        width: 100%;
    }
    ${RobotoFont}
  body {
        display: flex;
        height: 100vh;
        font: roboto, sans-serif;
    }
`;
