import React from "react";
import { ThemeProvider } from "styled-components";
import { theme, themeChakraUi } from "./styles/theme";
import { GlobalStyle } from "./styles/globalStyles";
import { Outlet } from "react-router-dom";
import LoginContextProvider from "./context/loginContext";
import UsuallyContextProvider from "@context/usuallyContex";
import { ChakraProvider } from "@chakra-ui/react";
import OportunityContexProvider from "@context/oportunityContex";
import { datadogRum } from "@datadog/browser-rum";
import MenuSideBar from "@components/common/MenuSideBar";

export const ThemeContext: any = React.createContext(null);

const handleConfigsByEnviroment = () => {
  if (window.location.href.includes("localhost")) return;

  if (window.location.href.includes("dev")) return;

  if (window.location.href.includes("qa")) return;

  if (window.location.href.includes("stage"))
    return {
      sessionReplaySampleRate: 0,
      sessionSampleRate: 100,
    };

  return {
    sessionReplaySampleRate: 20,
    sessionSampleRate: 50,
  };
};
const handleConfigsEnv = () => {
  if (window.location.href.includes("localhost")) return;

  if (window.location.href.includes("dev")) return "dev";

  if (window.location.href.includes("qa")) return "qa";

  if (window.location.href.includes("stage")) return "stage";

  return "prod";
};

datadogRum.init({
  applicationId: "ad82704a-fe05-44df-b5d2-8f140fff89ff",
  clientToken: "pubed4ba6a30b7d1b2b3bd7c69997536cac",
  // site refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: "boravender-web",
  env: handleConfigsEnv(),
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  ...handleConfigsByEnviroment,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [
    (url) => url.startsWith("https://apidev.direcional.com.br/corretorapi/v1"),
    (url) => url.startsWith("https://apihmg.direcional.com.br/corretorapi/v1"),
    (url) =>
      url.startsWith("https://apistage.direcional.com.br/corretorapi/v1"),
    (url) => url.startsWith("https://api.direcional.com.br/corretorapi/v1"),
    (url) => url.startsWith("https://backend-dev-eks.boravender.app.br"),
    (url) => url.startsWith("https://backend-qa-eks.boravender.app.br"),
    (url) => url.startsWith("https://backend-stage-eks.boravender.app.br"),
    (url) => url.startsWith("https://backend-prod-eks.boravender.app.br"),
  ],
});

function App() {
  const [themes, setThemes] = React.useState("");
  const themeGlobal = React.useMemo(() => ({ themes, setThemes }), [themes]);

  return (
    <LoginContextProvider>
      <UsuallyContextProvider>
        <OportunityContexProvider>
          <ThemeContext.Provider value={themeGlobal}>
            <ThemeProvider theme={theme}>
              <ChakraProvider theme={themeChakraUi}>
                <div
                  style={{ display: "flex", height: "100%", width: "100vw" }}
                >
                  <GlobalStyle />

                  <MenuSideBar  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Outlet />
                  </div>
                </div>
              </ChakraProvider>
            </ThemeProvider>
          </ThemeContext.Provider>
        </OportunityContexProvider>
      </UsuallyContextProvider>
    </LoginContextProvider>
  );
}

export default App;
