import { extendTheme } from "@chakra-ui/react";

export const theme = {
  backgroundGray: "#B3B3B333",
  backgroundPrimary: "#33558A",
  backgroundSecondary: "#C2C2C2",
  text: "#404140",
  text50: "#818281",
  disable: "#B3B3B3",
  disable100: "#F7F7F8",
  primary: "#002A6D",
  primary700: "#20356A",
  primary500: "#1D3054",
  available: "#28881B",
  available50: "#7EB776",
  error: "#C70137",
  error50: "#DD6687",
  notification: "#EF8D91",
  gold: "#D36008",
  gold50: "#fa8a34",
  info: "#ffaa00",
  info50: "#f7bd48",
};

export const themeChakraUi = extendTheme({
  colors: {
    radioButtonScheme: {
      100: theme.backgroundPrimary,
      500: theme.primary,
    },
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: "white",
            borderColor: theme.primary,
            color: theme.primary,
            _hover: {
              bg: "white",
              borderColor: theme.primary,
            },
          },
        },
      },
    },
  },
});
