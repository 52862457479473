import React from "react";
import * as Styled from "../../Steps/stylesStepTab";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { cepMask } from "@helpers/format";
import cepServiceInstance from "@services/Cep/cepService";
import { Box, Checkbox, Text } from "@chakra-ui/react";
import Spinner from "@components/common/Spinner";
import { IUpdateAdress, RelationshipAdress } from "src/@types/Sales";
import { optionUF } from "@helpers/optionSelect";
import salesServiceInstance from "@services/Sales/SalesService";
import { OportunityContext } from "@context/oportunityContex";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";

type Props = {
  relationship: RelationshipAdress[];
  edit: boolean;
  isModalUpdate?: boolean;
  setLoadingUpdateAdress: React.Dispatch<React.SetStateAction<boolean>>;
  isClicked?: boolean;
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
  isCancel?: boolean;
};

const IncomeComposersComponent = ({
  relationship,
  edit,
  isModalUpdate,
  setLoadingUpdateAdress,
  isClicked,
  setIsClicked,
  onSuccess,
  isCancel,
}: Props) => {
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const {
    isUpdateAdressIncome,
    setIsUpdateAdress,
    setIsUpdateAdressIncome,
    setRequireAdress,
    setSuccessUpdateAddresModal,
    accessCreateOp,
  } = React.useContext(OportunityContext);
  const [loadingCep, setLoadingCep] = React.useState(false);
  const [newRelationship, setNewRelationship] =
    React.useState<RelationshipAdress[]>();

  const filteredRelationships = relationship?.filter(
    (rel) => !rel.compradorPrincipal
  );

  const [residentialAddress, setResidentialAddress] = React.useState({
    accountIdSalesforce: "",
    idEnderecoSalesforce: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
  });

  const [correspondenceAddress, setCorrespondenceAddress] = React.useState({
    idEnderecoSalesforce: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
  });

  const [checkAdress, setCheckAdress] = React.useState(true);

  const [selectedName, setSelectedName] = React.useState(
    Array.isArray(filteredRelationships) && filteredRelationships.length > 0
      ? filteredRelationships[0].nome
      : ""
  );

  const selectedRelationship = filteredRelationships?.find(
    (rel) => rel?.nome === selectedName
  );

  const handleDataMainBuyer = relationship?.find(
    (rel) => !rel?.compradorPrincipal
  );

  const filteredNewRelationships = newRelationship?.filter(
    (rel) => !rel.compradorPrincipal
  );

  const selectedNewRelationship = filteredNewRelationships?.find(
    (rel) => rel?.nome === selectedName
  );

  const handleUpdateOportunity = async () => {
    try {
      const response = await oportunityServiceInstance.getOportunityById({
        OportunidadeId: accessCreateOp.oportunidadeId,
      });

      if (response.status === 401) {
        return;
      }

      setNewRelationship(response?.relacionamentosComprador);
    } catch (error) {
      console.log("ErrorHandleGetOportunityById", error);
    }
  };

  const handleUpdateAdress = async () => {
    setLoadingUpdateAdress(true);
    try {
      const body: IUpdateAdress[] = [
        {
          idEnderecoSalesforce: residentialAddress?.idEnderecoSalesforce,
          accountIdSalesforce: residentialAddress?.accountIdSalesforce,
          cep: residentialAddress.cep,
          numero: residentialAddress.numero,
          bairro: residentialAddress.bairro,
          logradouro: residentialAddress.logradouro,
          uf: residentialAddress.uf,
          complemento: residentialAddress.complemento,
          cidadeSAFI: residentialAddress.cidade,
          cidade: residentialAddress.cidade,
          tipoDeRegistro: "Residencial",
        },
        // Condicionalmente adicionar o endereço de correspondência
        ...(checkAdress
          ? [
              {
                idEnderecoSalesforce:
                  correspondenceAddress?.idEnderecoSalesforce,
                accountIdSalesforce: residentialAddress?.accountIdSalesforce,
                cep: residentialAddress.cep,
                numero: residentialAddress.numero,
                bairro: residentialAddress.bairro,
                logradouro: residentialAddress.logradouro,
                uf: residentialAddress.uf,
                complemento: residentialAddress.complemento,
                cidadeSAFI: residentialAddress.cidade,
                cidade: residentialAddress.cidade,
                tipoDeRegistro: "Correspondência",
              },
            ]
          : [
              {
                idEnderecoSalesforce:
                  correspondenceAddress?.idEnderecoSalesforce || "",
                accountIdSalesforce: residentialAddress?.accountIdSalesforce,
                cep: correspondenceAddress.cep,
                numero: correspondenceAddress.numero,
                bairro: correspondenceAddress.bairro,
                logradouro: correspondenceAddress.logradouro,
                uf: correspondenceAddress.uf,
                complemento: correspondenceAddress.complemento,
                cidadeSAFI: correspondenceAddress.cidade,
                cidade: correspondenceAddress.cidade,
                tipoDeRegistro: "Correspondência",
              },
            ]),
      ];

      if (
        handleDataMainBuyer?.enderecos[0]?.length === 0 ||
        filteredRelationships?.length === 0 ||
        residentialAddress.cep === ""
      ) {
        setLoadingUpdateAdress(false);
        setIsUpdateAdressIncome(false);
        setIsUpdateAdress(false);
        setIsClicked(false);
        setSuccessUpdateAddresModal(true);
        displayMessage("Oportunidade editada com sucesso!");
        onSuccess();
        handleUpdateOportunity();
        return;
      }

      const response = await salesServiceInstance.updateAdress(body);

      if (response?.data?.sucesso === false) {
        setLoadingUpdateAdress(false);
        setIsClicked(false);
        displayMessageError({ message: response?.data?.mensagem });
        setIsUpdateAdressIncome(false);
        setIsUpdateAdress(false);
        return;
      } else {
        setLoadingUpdateAdress(false);
        setIsUpdateAdressIncome(false);
        setIsUpdateAdress(false);
        setIsClicked(false);
        setSuccessUpdateAddresModal(true);
        displayMessage("Oportunidade editada com sucesso!");
        onSuccess();
        handleUpdateOportunity();
      }
    } catch (error) {
      setLoadingUpdateAdress(false);
      console.log("ErrorHandleUpdateAdress", error);
    }
  };

  const handleGetCep = async (cep: string, setAddress: any) => {
    setLoadingCep(true);
    try {
      const response = await cepServiceInstance.getCep({ cep });

      if (response.status === 401) {
        setLoadingCep(false);
        return;
      }

      setAddress((prevAddress: any) => ({
        ...prevAddress,
        logradouro: response?.street || "",
        bairro: response?.neighborhood || "",
        uf: response?.state || "",
        cidade: response?.city || "",
      }));

      setLoadingCep(false);
    } catch (error) {
      setLoadingCep(false);
      console.log("ErrorHandleGetCep", error);
    }
  };

  const handleCepChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setAddress: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const newCep = cepMask(e.target.value);

    setAddress((prevAddress: any) => ({
      ...prevAddress,
      cep: newCep,
    }));

    if (newCep.length === 9) {
      handleGetCep(newCep, setAddress);
    }
  };

  const handleCepComposerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setAddress: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const newCep = e.target.value;

    setAddress((prevAddress: any) => ({
      ...prevAddress,
      cep: cepMask(newCep),
    }));

    if (!checkAdress && cepMask(newCep).length === 9) {
      const handleGetCepCorrespondence = async () => {
        await handleGetCep(cepMask(newCep), setCorrespondenceAddress);
      };

      handleGetCepCorrespondence();
    }
  };

  React.useEffect(() => {
    const newResidential =
      !residentialAddress?.cep ||
      !residentialAddress?.logradouro ||
      !residentialAddress?.numero ||
      !residentialAddress?.bairro ||
      !residentialAddress?.uf ||
      !residentialAddress?.cidade;

    const newCorrespondence =
      (!checkAdress && !correspondenceAddress?.cep) ||
      (!checkAdress && !correspondenceAddress?.logradouro) ||
      (!checkAdress && !correspondenceAddress?.numero) ||
      (!checkAdress && !correspondenceAddress?.bairro) ||
      (!checkAdress && !correspondenceAddress?.uf) ||
      (!checkAdress && !correspondenceAddress?.cidade);

    setRequireAdress(newResidential || newCorrespondence);
  }, [
    checkAdress,
    correspondenceAddress?.bairro,
    correspondenceAddress?.cep,
    correspondenceAddress?.cidade,
    correspondenceAddress?.logradouro,
    correspondenceAddress?.numero,
    correspondenceAddress?.uf,
    residentialAddress?.bairro,
    residentialAddress?.cep,
    residentialAddress?.cidade,
    residentialAddress?.logradouro,
    residentialAddress?.numero,
    residentialAddress?.uf,
    setRequireAdress,
  ]);

  React.useEffect(() => {
    if (isCancel) {
      const address = selectedRelationship?.enderecos.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const newId = selectedRelationship?.enderecos.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const accountId = selectedRelationship?.accountIdSalesforce;

      const handleUseId = () => {
        if (!newId?.idEnderecoSalesforce && !address?.idEnderecoSalesforce)
          return "";
        if (newId?.idEnderecoSalesforce) return newId?.idEnderecoSalesforce;
        return address?.idEnderecoSalesforce;
      };

      setResidentialAddress({
        accountIdSalesforce: accountId || "",
        idEnderecoSalesforce: handleUseId(),
        cep: newId?.cep ? newId?.cep : address?.cep || "",
        logradouro: newId?.logradouro
          ? newId?.logradouro
          : address?.logradouro || "",
        numero: newId?.numero ? newId?.numero : address?.numero || "",
        complemento: newId?.complemento
          ? newId?.complemento
          : address?.complemento || "",
        bairro: newId?.bairro ? newId?.bairro : address?.bairro || "",
        cidade: newId?.cidade ? newId?.cidade : address?.cidade || "",
        uf: newId?.uf ? newId?.uf : address?.uf || "",
      });

      const correspondence = selectedRelationship?.enderecos?.find(
        (address: any) => address?.tipoDeRegistro?.includes("Correspo")
      );

      const newCorrespondence = selectedNewRelationship?.enderecos.find(
        (address) => address?.tipoDeRegistro?.includes("Correspo")
      ) || {
        idEnderecoSalesforce: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
      };

      if (address?.cep !== correspondence?.cep && correspondence?.cep) {
        setCheckAdress(false);
      }

      const handleUseIdCorrespondence = () => {
        if (
          !correspondence?.idEnderecoSalesforce &&
          !newCorrespondence?.idEnderecoSalesforce
        )
          return "";
        if (newCorrespondence?.idEnderecoSalesforce)
          return newCorrespondence?.idEnderecoSalesforce;
        return correspondence?.idEnderecoSalesforce;
      };
      setCorrespondenceAddress({
        idEnderecoSalesforce: handleUseIdCorrespondence(),
        cep: newCorrespondence?.cep
          ? newCorrespondence?.cep
          : correspondence?.cep || "",
        logradouro: newCorrespondence?.logradouro
          ? newCorrespondence?.logradouro
          : correspondence?.logradouro || "",
        numero: newCorrespondence?.numero
          ? newCorrespondence?.numero
          : correspondence?.numero || "",
        complemento: newCorrespondence?.complemento
          ? newCorrespondence?.complemento
          : correspondence?.complemento || "",
        bairro: newCorrespondence?.bairro
          ? newCorrespondence?.bairro
          : correspondence?.bairro || "",
        cidade: newCorrespondence?.cidade
          ? newCorrespondence?.cidade
          : correspondence?.cidade || "",
        uf: newCorrespondence?.uf
          ? newCorrespondence?.uf
          : correspondence?.uf || "",
      });
    }
  }, [handleDataMainBuyer, isCancel]);

  React.useEffect(() => {
    if (selectedRelationship || newRelationship) {
      const address = selectedRelationship?.enderecos.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const newId = selectedNewRelationship?.enderecos.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const accountId = selectedRelationship?.accountIdSalesforce;

      const handleUseId = () => {
        if (!newId?.idEnderecoSalesforce && !address?.idEnderecoSalesforce)
          return "";
        if (newId?.idEnderecoSalesforce) return newId?.idEnderecoSalesforce;
        return address?.idEnderecoSalesforce;
      };

      setResidentialAddress({
        accountIdSalesforce: accountId || "",
        idEnderecoSalesforce: handleUseId(),
        cep: newId?.cep ? newId?.cep : address?.cep || "",
        logradouro: newId?.logradouro
          ? newId?.logradouro
          : address?.logradouro || "",
        numero: newId?.numero ? newId?.numero : address?.numero || "",
        complemento: newId?.complemento
          ? newId?.complemento
          : address?.complemento || "",
        bairro: newId?.bairro ? newId?.bairro : address?.bairro || "",
        cidade: newId?.cidade ? newId?.cidade : address?.cidade || "",
        uf: newId?.uf ? newId?.uf : address?.uf || "",
      });

      const correspondence = selectedRelationship?.enderecos?.find(
        (address: any) => address?.tipoDeRegistro?.includes("Correspo")
      );

      const newCorrespondence = selectedNewRelationship?.enderecos.find(
        (address) => address?.tipoDeRegistro?.includes("Correspo")
      );

      if (
        (address?.cep !== correspondence?.cep ||
          newId?.cep !== newCorrespondence?.cep) &&
        (correspondence?.cep || newCorrespondence?.cep)
      ) {
        setCheckAdress(false);
      }

      const handleUseIdCorrespondence = () => {
        if (
          !correspondence?.idEnderecoSalesforce &&
          !newCorrespondence?.idEnderecoSalesforce
        )
          return "";
        if (newCorrespondence?.idEnderecoSalesforce)
          return newCorrespondence?.idEnderecoSalesforce;
        return correspondence?.idEnderecoSalesforce;
      };
      setCorrespondenceAddress({
        idEnderecoSalesforce: handleUseIdCorrespondence(),
        cep: newCorrespondence?.cep
          ? newCorrespondence?.cep
          : correspondence?.cep || "",
        logradouro: newCorrespondence?.logradouro
          ? newCorrespondence?.logradouro
          : correspondence?.logradouro || "",
        numero: newCorrespondence?.numero
          ? newCorrespondence?.numero
          : correspondence?.numero || "",
        complemento: newCorrespondence?.complemento
          ? newCorrespondence?.complemento
          : correspondence?.complemento || "",
        bairro: newCorrespondence?.bairro
          ? newCorrespondence?.bairro
          : correspondence?.bairro || "",
        cidade: newCorrespondence?.cidade
          ? newCorrespondence?.cidade
          : correspondence?.cidade || "",
        uf: newCorrespondence?.uf
          ? newCorrespondence?.uf
          : correspondence?.uf || "",
      });
    }
  }, [selectedNewRelationship, selectedRelationship, newRelationship]);

  React.useEffect(() => {
    if (isUpdateAdressIncome) {
      handleUpdateAdress();
    }
  }, [isUpdateAdressIncome]);

  return (
    <>
      <Styled.ContainerBoxInputs isModalUpdate={isModalUpdate}>
        <InputSelect
          title="Nome:"
          label={selectedName || "Selecione uma opção"}
          isSelected={selectedName}
          options={
            Array.isArray(filteredRelationships)
              ? filteredRelationships
                  .filter((rel) => !rel.compradorPrincipal)
                  .map((rel) => ({
                    name: rel.nome,
                    id: rel.idRelacionamentoComprador,
                  }))
              : []
          }
          selectedOption={selectedName}
          onChange={(e) => {
            const selectedName = e.target.value;
            setSelectedName(selectedName);
            const selectedRelationship: any = filteredRelationships?.find(
              (rel) => rel?.nome === selectedName
            );
            const correspondence = selectedRelationship?.enderecos?.find(
              (address: any) => address?.tipoDeRegistro?.includes("Correspo")
            );

            const residentialAdress = {
              accountIdSalesforce:
                selectedRelationship?.accountIdSalesforce || "",
              idEnderecoSalesforce:
                selectedRelationship?.enderecos[0]?.idEnderecoSalesforce || "",
              cep: selectedRelationship?.enderecos[0]?.cep || "",
              logradouro: selectedRelationship?.enderecos[0]?.logradouro || "",
              numero: selectedRelationship?.enderecos[0]?.numero || "",
              complemento:
                selectedRelationship?.enderecos[0]?.complemento || "",
              bairro: selectedRelationship?.enderecos[0]?.bairro || "",
              cidade: selectedRelationship?.enderecos[0]?.cidade || "",
              uf: selectedRelationship?.enderecos[0]?.uf || "",
            };

            const correspondenceAdress = {
              accountIdSalesforce:
                selectedRelationship?.accountIdSalesforce || "",
              idEnderecoSalesforce: correspondence?.idEnderecoSalesforce || "",
              cep: correspondence?.cep || "",
              logradouro: correspondence?.logradouro || "",
              numero: correspondence?.numero || "",
              complemento: correspondence?.complemento || "",
              bairro: correspondence?.bairro || "",
              cidade: correspondence?.cidade || "",
              uf: correspondence?.uf || "",
            };

            if (selectedRelationship && !correspondence?.cep) {
              setCheckAdress(true);
              setResidentialAddress(residentialAdress);
              setCorrespondenceAddress(correspondenceAdress);
            } else if (selectedRelationship && correspondence?.cep) {
              setResidentialAddress(residentialAdress);
              setCorrespondenceAddress(correspondenceAdress);
            } else {
              setCheckAdress(true);
              setResidentialAddress({
                accountIdSalesforce: "",
                idEnderecoSalesforce: "",
                cep: "",
                logradouro: "",
                numero: "",
                complemento: "",
                bairro: "",
                cidade: "",
                uf: "",
              });
              setCorrespondenceAddress(correspondenceAdress);
            }
          }}
        />
      </Styled.ContainerBoxInputs>

      {loadingCep ? (
        <Box
          display={"flex"}
          padding={"4px"}
          h={"100px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <Styled.ContainerTitleAdress>
            <span>Residencial</span>
          </Styled.ContainerTitleAdress>

          <Styled.ContainerBoxInputs>
            <InputTextEdit
              isRequirement={isModalUpdate}
              readOnly={!edit}
              label="CEP:"
              name="cep"
              value={residentialAddress?.cep}
              onChange={(e) => handleCepChange(e, setResidentialAddress)}
              maxLength={9}
            />

            <InputTextEdit
              isRequirement={isModalUpdate}
              readOnly={!edit}
              label="Logradouro:"
              name="publicPlace"
              value={residentialAddress?.logradouro}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  logradouro: e?.target?.value,
                }))
              }
            />

            <InputTextEdit
              isRequirement={isModalUpdate}
              readOnly={!edit}
              label="Número:"
              name="number"
              value={residentialAddress?.numero}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  numero: e?.target?.value,
                }))
              }
            />

            <InputTextEdit
              readOnly={!edit}
              name={"complement"}
              label="Complemento:"
              value={residentialAddress?.complemento}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  complemento: e?.target?.value,
                }))
              }
            />

            <InputTextEdit
              isRequirement={isModalUpdate}
              readOnly={!edit}
              label="Bairro:"
              name="neighborhood"
              value={residentialAddress?.bairro}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  bairro: e?.target?.value,
                }))
              }
            />

            <InputSelect
              isRequirement={isModalUpdate}
              readOnly={!edit}
              title="Estado:"
              label={residentialAddress?.uf || "Selecione uma opção"}
              isSelected={residentialAddress?.uf}
              options={optionUF}
              selectedOption={residentialAddress?.uf}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  uf: e?.currentTarget?.value,
                }))
              }
            />

            <InputTextEdit
              isRequirement={isModalUpdate}
              readOnly={!edit}
              label="Cidade:"
              name="city"
              value={residentialAddress?.cidade}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  cidade: e?.target?.value,
                }))
              }
            />
          </Styled.ContainerBoxInputs>

          <Styled.ContainerCheckboxAdress>
            <Checkbox
              disabled={!edit}
              isChecked={checkAdress}
              onChange={(e) => setCheckAdress(e.target.checked)}
              size="md"
            >
              <Text fontSize={"14px"}>
                O endereço de correspondência é igual ao residencial
              </Text>
            </Checkbox>
          </Styled.ContainerCheckboxAdress>

          {!checkAdress && (
            <>
              <Styled.ContainerTitleAdress>
                <span>Correspondência</span>
              </Styled.ContainerTitleAdress>

              <Styled.ContainerBoxInputs>
                <InputTextEdit
                  isRequirement={isModalUpdate}
                  readOnly={!edit}
                  label="CEP:"
                  name="cep"
                  value={correspondenceAddress?.cep}
                  onChange={(e) =>
                    handleCepComposerChange(e, setCorrespondenceAddress)
                  }
                  maxLength={9}
                />

                <InputTextEdit
                  isRequirement={isModalUpdate}
                  readOnly={!edit}
                  label="Logradouro:"
                  name="publicPlace"
                  value={correspondenceAddress?.logradouro}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      logradouro: e?.target?.value,
                    }))
                  }
                />

                <InputTextEdit
                  isRequirement={isModalUpdate}
                  readOnly={!edit}
                  label="Número:"
                  name="number"
                  value={correspondenceAddress?.numero}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      numero: e?.target?.value,
                    }))
                  }
                />

                <InputTextEdit
                  readOnly={!edit}
                  name={"complement"}
                  label="Complemento:"
                  value={correspondenceAddress?.complemento}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      complemento: e?.target?.value,
                    }))
                  }
                />

                <InputTextEdit
                  isRequirement={isModalUpdate}
                  readOnly={!edit}
                  label="Bairro:"
                  name="neighborhood"
                  value={correspondenceAddress?.bairro}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      bairro: e?.target?.value,
                    }))
                  }
                />

                <InputSelect
                  isRequirement={isModalUpdate}
                  readOnly={!edit}
                  title="Estado:"
                  label={correspondenceAddress?.uf || "Selecione uma opção"}
                  isSelected={correspondenceAddress?.uf}
                  options={optionUF}
                  selectedOption={correspondenceAddress?.uf}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      uf: e?.currentTarget?.value,
                    }))
                  }
                />

                <InputTextEdit
                  isRequirement={isModalUpdate}
                  readOnly={!edit}
                  label="Cidade:"
                  name="city"
                  value={correspondenceAddress?.cidade}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      cidade: e?.target?.value,
                    }))
                  }
                />
              </Styled.ContainerBoxInputs>
            </>
          )}
        </>
      )}
    </>
  );
};

export default IncomeComposersComponent;
