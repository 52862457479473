import React from "react";
import * as Styled from "../styles";
import Button from "@components/common/Button";
import { RxUpdate } from "react-icons/rx";
import InputSelect from "@components/Enterprises/Inputs/InputSelect";
import InputSearch from "@components/Enterprises/Inputs/InputSearch";
import DataTable from "@components/Enterprises/Table/DataTable";
import TabFilter from "@components/Enterprises/TabFilter";
import { BiBuilding } from "react-icons/bi";
import enterprisesService from "@services/Enterprises/enterprisesService";
import Spinner from "@components/common/Spinner";
import Alert from "@components/common/Alert";
import { UsuallyContext } from "@context/usuallyContex";
import { LoginContext } from "@context/loginContext";
import { capitalize } from "@helpers/format";
import { useNavigate } from "react-router-dom";

const EnterpriseComponent: React.FC = () => {
  const navigate = useNavigate();
  const { signOut, regional, setRegional, company, setCompany } =
    React.useContext(LoginContext);
  const { setNameEnterprisesBreadcrumb } = React.useContext(UsuallyContext);
  const [selectedRegional, setSelectedRegional] = React.useState("");
  const [searchEnterprises, setSearchEnterprises] = React.useState("");
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [nameUpdate, setNameUpdate] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState("Todos");
  const [regions, setRegions] = React.useState<any[]>([]);

  const handleOptionChange = (event: any) => {
    setSelectedRegional(event.target.value);
    handleGetEnterprises(event.target.value);
  };

  const handleRegional = (updateRegional: string = selectedRegional) => {
    const parts = updateRegional?.split(" ");

    if (!parts[0]) return regional;
    else if (updateRegional === "Todos os empreendimentos") return "";
    else if (
      parts[0] &&
      (updateRegional === "SP Capital Riva" ||
        updateRegional === "SP Capital Direcional")
    )
      return "SPC";
    else if (
      parts[1] &&
      (updateRegional === "SP Interior Riva" ||
        updateRegional === "SP Interior Direcional")
    )
      return "SPI";
    else return parts[0];
  };

  const handleCompany = (updateRegional: string = selectedRegional) => {
    const parts = updateRegional?.split(" ");

    if (!parts[1]) return company;
    else if (updateRegional === "Todos os empreendimentos") return "";
    else if (
      parts[1] &&
      (updateRegional === "SP Capital Riva" ||
        updateRegional === "SP Interior Riva")
    )
      return "Riva";
    else if (
      parts[1] &&
      (updateRegional === "SP Capital Direcional" ||
        updateRegional === "SP Interior Direcional")
    )
      return "Direcional";
    else return parts[1];
  };

  const handleGetEnterprises = async (
    updateRegional: string = selectedRegional
  ) => {
    setLoading(true);
    try {
      const [tempRegional, empresa, regiao] = updateRegional.split(" ");

      const response = await enterprisesService.getEnterprises({
        regional: tempRegional,
        unidadeDeNegocio: empresa,
        regiao: regiao ?? null,
      });

      if (!response) {
        setLoading(false);
        setErrorEmpty(true);
        return;
      }
      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        console.log("status 400", response);
        return;
      }
      if (response?.status === 401) {
        setLoading(false);
        console.log("status 401", response);
        setError(response.status === 401);
        setRegions([]);
        return;
      }

      const dataArray: any = Object.values(response);
      setData(dataArray);

      setRegional(tempRegional);
      setCompany(empresa);
      setNameUpdate(updateRegional);

      setNameEnterprisesBreadcrumb(
        `${handleRegional(updateRegional)} ${handleCompany(updateRegional)}`
      );
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const handleTabClick = (tab: any) => {
    setSelectedTab(tab);
  };

  const memoRegional = React.useMemo(
    () =>
      regions?.map(({ regional, unidadeDeNegocio, regiao }) => {
        const formattedValue = `${regional} ${unidadeDeNegocio} ${
          regiao ?? ""
        }`.trimEnd();

        return (
          <option key={formattedValue} value={formattedValue}>
            {formattedValue}
          </option>
        );
      }),
    [regions]
  );

  React.useEffect(() => {
    const fetchRegions = async () => {
      let response = await enterprisesService.getRegions();

      if (response?.status === 401) {
        setError(response.status === 401);
        setRegions([]);
        return;
      }

      if (response) {
        setRegions(response);
      }
    };

    fetchRegions();
  }, []);

  React.useEffect(() => {
    regions && handleGetEnterprises(`${regional} ${capitalize(company)}`);
  }, []);

  React.useLayoutEffect(() => {
    if (!company) {
      setSelectedRegional("");
    } else {
      setSelectedRegional(`${regional} ${capitalize(company)}`);
    }
  }, []);

  return (
    <>
      <Alert
        onOpen={error || errorEmpty}
        title={
          errorEmpty
            ? "Empreendimentos Indisponíveis!"
            : "Erro ao carregar os dados!"
        }
        titleButton={errorEmpty ? "Voltar" : "Logar novamente"}
        description={
          errorEmpty
            ? "Não há empreendimentos para os filtros selecionados."
            : "Sua sessão expirou, faça login novamante para ter acesso."
        }
        onClick={() => {
          if (errorEmpty) {
            setErrorEmpty(false);
          } else {
            signOut();
          }
        }}
      />
      <Styled.ContainerTitlePage>
        <Styled.ContainerTitle>
          <BiBuilding />
          <Styled.TitlePage>Empreendimentos</Styled.TitlePage>
        </Styled.ContainerTitle>

        <Styled.ContainerTitleButtonsEnterprise>
          <Button
            title={
              <Styled.AreaTitleButtons>
                <RxUpdate />
                ATUALIZAR
              </Styled.AreaTitleButtons>
            }
            onClick={() => handleGetEnterprises()}
          />
        </Styled.ContainerTitleButtonsEnterprise>
      </Styled.ContainerTitlePage>

      <Styled.ContainerDisplay>
        <Styled.TitleDisplay>Modo de exibição:</Styled.TitleDisplay>
        <Styled.ContainerDisplayInput>
          <InputSelect value={selectedRegional} onChange={handleOptionChange}>
            {memoRegional}
          </InputSelect>
        </Styled.ContainerDisplayInput>
      </Styled.ContainerDisplay>

      <Styled.ContainerTable>
        {loading ? (
          <Styled.ContainerSpinner>
            <Spinner />
          </Styled.ContainerSpinner>
        ) : (
          <>
            <Styled.ContainerTitleTableEnterprise>
              <Styled.TitleTable>
                {!nameUpdate ? `${regional} ${company}` : nameUpdate}
              </Styled.TitleTable>
              <InputSearch
                placeholder="Pesquisar empreendimento"
                value={searchEnterprises}
                onChange={(e) => setSearchEnterprises(e.target.value)}
              />
            </Styled.ContainerTitleTableEnterprise>

            <Styled.TableEnterprise>
              <DataTable
                data={data}
                onClick={() => navigate("/detalhesEmpreendimentos")}
                nameFilter={searchEnterprises}
                selectedTab={selectedTab}
              />
            </Styled.TableEnterprise>

            <Styled.ContainerFooterTable>
              <Styled.ContainerTabFooter>
                <TabFilter onTabClick={handleTabClick} />
              </Styled.ContainerTabFooter>
            </Styled.ContainerFooterTable>
          </>
        )}
      </Styled.ContainerTable>
    </>
  );
};

export default EnterpriseComponent;
