import React from "react";
import { CloseButton, useToast } from "@chakra-ui/react";
import "react-toastify/dist/ReactToastify.css";
import Button from "@components/common/Button";
import * as Styled from "./styles";

interface INotify {
  onOpen: boolean;
  noContent?: boolean;
  noButton?: boolean;
  isSuccess?: boolean;
  title: string;
  message?: string;
  titleButton: string;
  onClick: React.FormEventHandler<HTMLButtonElement>;
  height?: string;
}

const ToastNotify = ({
  onOpen,
  noContent,
  noButton,
  isSuccess,
  title,
  message,
  titleButton,
  onClick,
  height,
}: INotify) => {
  const toast = useToast();
  const toastIdRef: React.MutableRefObject<any> = React.useRef();

  const handlebutton = (e: any) => {
    onClick(e);
    toast.close(toastIdRef.current);
  };

  const Message = () => {
    return (
      <Styled.Container
        noContent={noContent}
        noButton={noButton}
        height={height}
      >
        <Styled.ContainerHeader>
          <span>Bora Vender</span>
          <CloseButton onClick={() => toast.close(toastIdRef.current)} />
        </Styled.ContainerHeader>

        <Styled.ContainerTitleSuccess noContent={noContent}>
          <span>{title}</span>
        </Styled.ContainerTitleSuccess>

        {!noButton && (
          <>
            {!noContent && (
              <Styled.Obs>
                <span>{message}</span>
              </Styled.Obs>
            )}

            <Styled.ContainerButton noContent={noContent}>
              <Button title={titleButton} onClick={handlebutton} />
            </Styled.ContainerButton>
          </>
        )}
      </Styled.Container>
    );
  };

  const MessageSuccess = () => {
    return (
      <Styled.ContainerSuccess>
        <Styled.ContainerHeaderSuccess>
          <span>Bora Vender</span>
          <CloseButton onClick={() => toast.close(toastIdRef.current)} />
        </Styled.ContainerHeaderSuccess>

        <Styled.ContainerTitleSuccess noContent={noContent}>
          <span>{title}</span>
        </Styled.ContainerTitleSuccess>
      </Styled.ContainerSuccess>
    );
  };

  function handleMessage() {
    return isSuccess ? <MessageSuccess /> : <Message />;
  }

  const displayMessage = () => {
    toast.close(toastIdRef.current);
    toastIdRef.current = toast({
      position: "top-right",
      status: undefined,
      duration: 6000,
      onCloseComplete: () => toast.close(toastIdRef.current),
      render: () => handleMessage(),
      containerStyle: {
        maxWidth: "350px",
        marginTop: "5px",
        boxShadow: "dark-lg",
        borderRadius: "8px",
        bg: "white",
      },
    });
  };

  React.useEffect(() => {
    if (onOpen) {
      displayMessage();
    } else {
      toast.close(toastIdRef.current);
    }
  }, [onOpen, toast]);

  return <div />;
};

export default ToastNotify;
