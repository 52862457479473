import { FiUsers } from "react-icons/fi";
import { BiBuilding } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";

export const linkSidebar = [
  {
    label: "Notificações",
    icon: (
      <svg
        width="22"
        height="22"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 13.1923L11.3077 9.8845L10.6 9.177L8.5 11.277V6.577H7.5V11.277L5.4 9.177L4.69225 9.8845L8 13.1923ZM1 3.80775V14.3845C1 14.564 1.05767 14.7115 1.173 14.827C1.2885 14.9423 1.436 15 1.6155 15H14.3845C14.564 15 14.7115 14.9423 14.827 14.827C14.9423 14.7115 15 14.564 15 14.3845V3.80775H1ZM1.76925 16C1.32175 16 0.915 15.817 0.549 15.451C0.183 15.085 0 14.6783 0 14.2308V3.4865C0 3.29167 0.0310832 3.10833 0.0932499 2.9365C0.155417 2.76467 0.248667 2.60633 0.373 2.4615L1.93075 0.5905C2.07558 0.3955 2.25667 0.248334 2.474 0.149001C2.69133 0.0496672 2.92433 0 3.173 0H12.7885C13.0372 0 13.2733 0.0496672 13.497 0.149001C13.7208 0.248334 13.9052 0.3955 14.05 0.5905L15.627 2.5C15.7513 2.64483 15.8446 2.80633 15.9068 2.9845C15.9689 3.16283 16 3.34942 16 3.54425V14.2308C16 14.6783 15.817 15.085 15.451 15.451C15.085 15.817 14.6783 16 14.2308 16H1.76925ZM1.38075 2.80775H14.6L13.2693 1.2115C13.2051 1.1475 13.1313 1.09625 13.048 1.05775C12.9647 1.01925 12.8782 1 12.7885 1H3.19225C3.10258 1 3.01608 1.01925 2.93275 1.05775C2.84942 1.09625 2.77567 1.1475 2.7115 1.2115L1.38075 2.80775Z"
          fill="#FFF"
        />
      </svg>
    ),
    to: "",
  },
  {
    label: "Criar oportunidade",
    // subItem: [{ name: "Buscar clientes" }, { name: "Minhas oportunidades" }],
    icon: (
      <svg
        width="22"
        height="22"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 7.69226C7.0375 7.69226 6.21358 7.34959 5.52825 6.66426C4.84275 5.97876 4.5 5.15476 4.5 4.19226C4.5 3.22976 4.84275 2.40584 5.52825 1.72051C6.21358 1.03501 7.0375 0.692261 8 0.692261C8.9625 0.692261 9.78642 1.03501 10.4718 1.72051C11.1573 2.40584 11.5 3.22976 11.5 4.19226C11.5 5.15476 11.1573 5.97876 10.4718 6.66426C9.78642 7.34959 8.9625 7.69226 8 7.69226ZM18.6578 18.7115L15.727 15.7808C15.3898 15.9936 15.034 16.1603 14.6595 16.2808C14.2852 16.4013 13.8993 16.4615 13.502 16.4615C12.3982 16.4615 11.4616 16.0762 10.6923 15.3055C9.92308 14.5347 9.5385 13.5988 9.5385 12.4978C9.5385 11.3968 9.92383 10.4616 10.6945 9.69226C11.4653 8.92309 12.4013 8.53851 13.5023 8.53851C14.6033 8.53851 15.5384 8.92368 16.3077 9.69401C17.0769 10.4643 17.4615 11.3997 17.4615 12.5C17.4615 12.8987 17.4013 13.2852 17.2808 13.6595C17.1602 14.034 16.9936 14.3898 16.7808 14.727L19.7115 17.6578L18.6578 18.7115ZM13.5 14.9615C14.1937 14.9615 14.7773 14.7247 15.251 14.251C15.7247 13.7773 15.9615 13.1937 15.9615 12.5C15.9615 11.8063 15.7247 11.2227 15.251 10.749C14.7773 10.2753 14.1937 10.0385 13.5 10.0385C12.8063 10.0385 12.2227 10.2753 11.749 10.749C11.2753 11.2227 11.0385 11.8063 11.0385 12.5C11.0385 13.1937 11.2753 13.7773 11.749 14.251C12.2227 14.7247 12.8063 14.9615 13.5 14.9615ZM8.448 9.32701C7.88267 10.2385 7.58075 11.2321 7.54225 12.3078C7.50392 13.3833 7.74817 14.3833 8.275 15.3078H0.5V13.1C0.5 12.6038 0.6305 12.1478 0.8915 11.7318C1.15233 11.3158 1.51033 10.9923 1.9655 10.7615C2.75133 10.3667 3.69842 10.0161 4.80675 9.70976C5.91508 9.40326 7.12883 9.27568 8.448 9.32701Z"
          fill="#FFF"
        />
      </svg>
    ),
    to: "/oportunidades",
  },
  {
    label: "Minhas oportunidades",
    // subItem: [{ name: "Buscar clientes" }, { name: "Minhas oportunidades" }],
    icon: (
      <svg
        width="22"
        height="22"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.38443 19V12.0442L0.961426 6.5L4.97118 0H13.0287L17.0384 6.5L13.6154 12.0442V19L8.99993 17.4038L4.38443 19ZM5.38443 17.5328L8.99993 16.323L12.6154 17.5328V13H5.38443V17.5328ZM5.51918 1L2.11918 6.5L5.51918 12H12.4807L15.8807 6.5L12.4807 1H5.51918ZM7.94993 9.8635L5.11143 7.05L5.82493 6.3365L7.94993 8.4615L12.1749 4.2115L12.8884 4.9L7.94993 9.8635Z"
          fill="#FFF"
        />
      </svg>
    ),
    to: "/oportunidades",
  },
  {
    label: "Empreendimentos",
    icon: (
      <svg
        width="22"
        height="22"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.730713 17.1538V0.153809H9.73071V4.15381H19.2692V17.1538H0.730713ZM1.73071 16.1538H4.73071V13.1538H1.73071V16.1538ZM1.73071 12.1538H4.73071V9.15381H1.73071V12.1538ZM1.73071 8.15381H4.73071V5.15381H1.73071V8.15381ZM1.73071 4.15381H4.73071V1.15381H1.73071V4.15381ZM5.73071 16.1538H8.73071V13.1538H5.73071V16.1538ZM5.73071 12.1538H8.73071V9.15381H5.73071V12.1538ZM5.73071 8.15381H8.73071V5.15381H5.73071V8.15381ZM5.73071 4.15381H8.73071V1.15381H5.73071V4.15381ZM9.73071 16.1538H18.2692V5.15381H9.73071V8.15381H12.1155V9.15381H9.73071V12.1538H12.1155V13.1538H9.73071V16.1538ZM14.5 9.15381V8.15381H15.5V9.15381H14.5ZM14.5 13.1538V12.1538H15.5V13.1538H14.5Z"
          fill="#FFF"
        />
      </svg>
    ),
    to: "/empreendimentos",
  },
  {
    label: "Comissões",
    subItem: [{ name: "Minhas comissões" }],
    icon: (
      <svg
        width="22"
        height="22"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.003 14C11.0497 14 9.39417 13.3222 8.0365 11.9665C6.67883 10.6108 6 8.95633 6 7.003C6 5.04967 6.67783 3.39417 8.0335 2.0365C9.38917 0.678834 11.0437 0 12.997 0C14.9503 0 16.6058 0.677834 17.9635 2.0335C19.3212 3.38917 20 5.04367 20 6.997C20 8.95033 19.3222 10.6058 17.9665 11.9635C16.6108 13.3212 14.9563 14 13.003 14ZM5 13.7115C3.48967 13.309 2.27875 12.484 1.36725 11.2365C0.45575 9.98917 0 8.577 0 7C0 5.423 0.45575 4.01083 1.36725 2.7635C2.27875 1.516 3.48967 0.690999 5 0.288499V1.35C3.8 1.76667 2.83333 2.49167 2.1 3.525C1.36667 4.55833 1 5.71667 1 7C1 8.28333 1.36667 9.44167 2.1 10.475C2.83333 11.5083 3.8 12.2333 5 12.65V13.7115ZM13 13C14.6667 13 16.0833 12.4167 17.25 11.25C18.4167 10.0833 19 8.66667 19 7C19 5.33333 18.4167 3.91667 17.25 2.75C16.0833 1.58333 14.6667 1 13 1C11.3333 1 9.91667 1.58333 8.75 2.75C7.58333 3.91667 7 5.33333 7 7C7 8.66667 7.58333 10.0833 8.75 11.25C9.91667 12.4167 11.3333 13 13 13Z"
          fill="#FFF"
        />
      </svg>
    ),
    to: "/comissoes",
  },
];
