interface IIcon {
  width: string;
  height: string;
}

export const IconQuotes = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.30775 13.6923H5.19225V11.6923H7.19225V10.8077H5.19225V8.80775H4.30775V10.8077H2.30775V11.6923H4.30775V13.6923ZM9.30775 12.9423H13.6923V12.0577H9.30775V12.9423ZM9.30775 10.4423H13.6923V9.55775H9.30775V10.4423ZM10.1 6.4885L11.5 5.0885L12.9 6.4885L13.527 5.8615L12.127 4.45L13.527 3.05L12.9 2.423L11.5 3.823L10.1 2.423L9.473 3.05L10.873 4.45L9.473 5.8615L10.1 6.4885ZM2.55775 4.89225H6.94225V4.00775H2.55775V4.89225ZM1.6155 16C1.15517 16 0.770833 15.8458 0.4625 15.5375C0.154167 15.2292 0 14.8448 0 14.3845V1.6155C0 1.15517 0.154167 0.770833 0.4625 0.4625C0.770833 0.154167 1.15517 0 1.6155 0H14.3845C14.8448 0 15.2292 0.154167 15.5375 0.4625C15.8458 0.770833 16 1.15517 16 1.6155V14.3845C16 14.8448 15.8458 15.2292 15.5375 15.5375C15.2292 15.8458 14.8448 16 14.3845 16H1.6155ZM1.6155 15H14.3845C14.5385 15 14.6796 14.9359 14.8077 14.8077C14.9359 14.6796 15 14.5385 15 14.3845V1.6155C15 1.4615 14.9359 1.32042 14.8077 1.19225C14.6796 1.06408 14.5385 1 14.3845 1H1.6155C1.4615 1 1.32042 1.06408 1.19225 1.19225C1.06408 1.32042 1 1.4615 1 1.6155V14.3845C1 14.5385 1.06408 14.6796 1.19225 14.8077C1.32042 14.9359 1.4615 15 1.6155 15Z"
      fill="#263958"
    />
  </svg>
);
