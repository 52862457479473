import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";
import styled from "styled-components";

type IEnterprises = {
  open?: boolean;
  hash?: string;
};

export const Area = styled.div<IEnterprises>`
  display: flex;
  position: relative;
  flex-direction: column;
  left: ${({ open }) => (!open ? " 80px" : "230px")};
  width: ${({ open }) => (!open ? `calc(100% - 80px)` : `calc(100% - 230px)`)};
  height: 100%;
  background: ${theme.disable100};
  transition: all 0.2s ease;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  > form {
    display: flex;
    flex-direction: column;
  }

  @media ${device.tablet} {
    width: 100%;
    left: 0;
  }
`;

export const Container = styled.div<IEnterprises>`
  display: flex;
  position: relative;
  flex-direction: column;
  height: max-content;

  @media ${device.laptopXL} {
    height: ${({ hash }) => (hash === "Enterprise" ? "100%" : "max-content")};
  }
`;

export const SubContainer = styled.div`
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  height: max-content;
  padding-top: 10px;

  @media ${device.laptop} {
    padding-left: 10px;
  }
  @media ${device.mobileL} {
    padding-left: 3px;
  }
`;

export const ContainerTop = styled.section`
  display: flex;
  width: 100%;
  margin-top: 1px;

  @media ${device.tablet} {
    margin-top: 42px;
  }
`;

export const ContainerTitlePage = styled.section`
  display: flex;
  width: 100%;
  height: 13%;
  align-items: center;

  @media ${device.tablet} {
    align-items: baseline;
    flex-direction: column;
    height: 100px;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: baseline;
    height: 200px;
  }
`;
export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;

  > svg {
    display: flex;
    width: fit-content;
    font-size: 26px;
  }
`;
export const TitlePage = styled.span`
  width: 90%;
  font-size: 30px;
  font-weight: bold;
  color: ${theme.text};

  @media ${device.mobileL} {
    width: 100%;
    font-size: 25px;
    margin-bottom: 8px;
  }
`;

export const ContainerTitleButtonsEnterprise = styled.div`
  display: flex;
  width: 15%;
  margin-right: 15px;

  @media ${device.laptop} {
    width: 20%;
  }
  @media ${device.tablet} {
    width: 30%;
  }
  @media ${device.mobileL} {
    width: 45%;
  }
`;

export const AreaTitleButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  > svg {
    margin-right: 6px;
    color: white;
    font-size: 16px;
  }
`;

export const ContainerDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 15%;

  @media print {
    display: none;
  }

  @media ${device.laptop} {
    width: 60%;
    margin-top: 10px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-top: 10px;
  }
  @media ${device.mobileL} {
    margin-top: 70px;
    width: 96%;
  }
`;
export const TitleDisplay = styled.span`
  display: flex;
  font-size: 13px;
`;

export const ContainerDisplayInput = styled.div`
  display: flex;
  width: 70%;
`;

export const ContainerSearchDetailEnterprise = styled.div`
  display: flex;
  width: 80%;
`;

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 100%;
  margin-top: 12px;
  background: white;
  border-radius: 8px;

  @media ${device.laptop} {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const ContainerTitleTableEnterprise = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 1px 10px;

  @media ${device.mobileL} {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
`;
export const ContainerTitleTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 1px 10px;

  @media ${device.mobileL} {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
`;

export const TitleTable = styled.span`
  display: flex;
  font-size: 22px;
  font-weight: bold;
  color: ${theme.text};

  @media ${device.laptop} {
    margin-bottom: 3px;
  }
  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const ContainerFooterTable = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 10px;

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerTabFooter = styled.div`
  display: flex;
  width: 380px;
  height: fit-content;

  @media ${device.mobileL} {
    margin-bottom: 10px;
  }
`;

export const ContainerTabPagination = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 3px;

  > svg {
    width: fit-content;
    color: ${theme.primary};
  }

  > a {
    display: flex;
    color: ${theme.primary};
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ContainerTitleButtonEnterprises = styled.div`
  display: flex;
  width: 50%;
  margin-right: 15px;
  gap: 10px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
  @media ${device.laptop} {
    width: 80%;
  }
`;

export const AreaTitleButtonEnterprises = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  > svg {
    margin-right: 6px;
    color: white;
    font-size: 16px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContainerSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background: white;
  margin-top: 20px;
  border-radius: 8px;
  padding: 10px 12px;

  @media print {
    margin-top: 50px;
  }

  @media ${device.laptop} {
    padding: 10px 4px;
  }
`;

export const ContainerTitleSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 30px;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    > svg {
      display: flex;
      color: ${theme.text};
      width: fit-content;
      font-size: 22px;
      cursor: pointer;
    }
  }
`;

export const TitleSection = styled.span`
  display: flex;
  font-size: 23px;
  font-weight: bold;
  color: ${theme.text};
`;

export const ContainerInput = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media print {
    grid-row-gap: 25px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;

export const ContainerInputValues = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media print {
    grid-row-gap: 25px;
  }

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: fit-content;
  }
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;

export const SubContainerInput = styled.section`
  display: flex;
  width: 100%;
  padding-right: 15px;

  @media ${device.tablet} {
    width: 80%;
  }
`;

export const ContainerTableDuo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media ${device.laptopXL} {
    flex-direction: row;
    gap: 10px;
  }
  @media ${device.mobileL} {
    max-width: 100%;
  }
`;

export const ContainerTableDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 15px;
  background: white;
  border-radius: 8px;

  @media ${device.laptopXL} {
    height: 550px;
  }
`;

export const ContainerTableAttachment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 15px;
  background: white;
  margin-bottom: 20px;
  border-radius: 8px;

  @media ${device.laptopXL} {
    height: 550px;
  }
`;

export const TableEnterprise = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  @media ${device.laptopXL} {
    height: 500px;
  }
  @media ${device.laptop} {
    height: 300px;
  }
  @media ${device.tablet} {
    height: 400px;
  }
  @media ${device.mobileL} {
    padding-left: 0px;
    overflow: auto;
  }
`;

export const TableDetail = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 65%;

  @media ${device.laptopXL} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobileL} {
    overflow: auto;
  }
`;

export const TableDetailModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.primary};
    border-radius: 20px;
  }

  @media ${device.laptopXL} {
    width: 100%;
    height: 500px;
  }

  @media ${device.mobileL} {
    overflow: auto;
    height: 450px;
  }
`;

export const SalesMirrorItems = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  height: max-content;
  width: 100%;
`;

export const TableAttachment = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 65%;

  @media ${device.laptopXL} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobileL} {
    overflow: auto;
  }
`;

export const ContainerFooterTableDetail = styled.div`
  display: flex;
  width: 66%;
  padding: 10px 20px;
  justify-content: flex-end;

  @media ${device.mobileL} {
    width: 100%;
  }
  @media ${device.laptopXL} {
    width: 100%;
  }
`;

export const ContainerFooterButton = styled.div`
  display: flex;
  width: 35%;

  @media ${device.laptopXL} {
    width: 40%;
  }
  @media ${device.tablet} {
    width: 35%;
  }
  @media ${device.mobileL} {
    width: 65%;
    margin-bottom: 10px;
  }
`;
export const SubContainerFooterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > svg {
    display: flex;
    width: fit-content;
    font-size: 23px;
  }
`;

export const ContainerFooterTableAttachment = styled.div`
  display: flex;
  width: 65%;
  padding: 10px 20px;
  justify-content: space-between;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerTitleButtonUnity = styled.div`
  display: flex;
  width: 50%;
  margin-right: 15px;
  gap: 10px;

  @media print {
    display: none;
  }

  @media ${device.laptop} {
    width: 65%;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;

export const ContainerSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    height: 400px;
  }
`;
export const ContainerUnity = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    padding-left: 10px;
  }
`;

export const ContainerSearchUnity = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-top: 15px;

  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
  @media ${device.laptopXL} {
    width: 100%;
  }
`;
export const ContainerSearchUnityModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 10px;

  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;
export const ContainerSearchAttachmentModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

export const ContainerSectionModal = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background: white;
  margin-top: 20px;
  border-radius: 8px;
  padding: 10px 12px;

  @media ${device.laptopXL} {
    height: 600px;
  }
  @media ${device.tablet} {
    height: 450px;
  }
  @media ${device.laptop} {
    margin-top: 10px;
    height: 380px;
  }
`;
export const ContainerSectionSalesMirrorModal = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background: white;
  margin-top: 20px;
  border-radius: 8px;
  padding: 10px 12px;

  @media ${device.laptopXL} {
    height: max-content;
    min-height: 600px;
  }
  @media ${device.tablet} {
    height: max-content;
    min-height: 450px;
  }
  @media ${device.laptop} {
    margin-top: 10px;
    height: max-content;
    min-height: 450px;
  }
`;

export const ContainerSearchMessageUnityModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid ${theme.disable100};
  padding: 5px;

  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;

export const BodySearchUnityModalMessage = styled.span`
  display: flex;
  font-size: 12px;
  color: ${theme.text};
`;
export const BodySearchUnityModalMessageError = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.error};
`;

export const ContainerFilterCondition = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  margin-top: 6px;

  @media ${device.mobileL} {
    height: min-content;
  }
`;
export const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 4px;
  max-height: 4px;
  min-height: 4px;
  background: ${theme.disable};
  margin-top: 8px;
  margin-bottom: 8px;

  @media ${device.laptop} {
    margin-top: 25px;
    margin-bottom: 15px;
  }
`;
export const ContainerAccordion = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
