import React from "react";
import { Flex, Box, Button, Input, InputGroup, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import Spinner from "@components/common/Spinner";

interface IInputSearch {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: any;
  maxLength?: number;
  isOportunity?: boolean;
  disabledButton?: boolean;
  onClickButton?: () => void;
  loadingButton?: boolean;
}

const InputSearch = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  maxLength,
  isOportunity,
  disabledButton,
  onClickButton,
  loadingButton,
}: IInputSearch) => {
  function borderTheme() {
    if (disabledButton) return "none";
    return "none";
  }
  function backgroundTheme() {
    if (disabledButton) return `${theme.disable}`;
    if (error) return "white";
    return `${theme.primary}`;
  }
  function textTheme() {
    if (disabledButton) return "white";
    if (error) return `${theme.error}`;
    return "white";
  }

  return (
    <Flex
      flexDir={isOportunity ? "column" : "row"}
      w={"100%"}
      h={"fit-content"}
      alignItems={"center"}
    >
      {label && (
        <Text fontSize={"12px"} color={theme.text} ml={"5px"}>
          {label}
        </Text>
      )}

      <InputGroup>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isInvalid={error}
          focusBorderColor={theme.primary}
          borderWidth={"2px"}
          borderColor={isOportunity ? theme.disable100 : theme.disable}
          borderRightColor={isOportunity ? theme.disable100 : "transparent"}
          height={isOportunity ? "44px" : "40px"}
          borderRadius={8}
          borderRightRadius={isOportunity ? "8px" : 0}
          fontSize={14}
          _placeholder={{ color: theme.disable }}
          _hover={{ color: "none" }}
          _focus={{
            borderWidth: isOportunity ? "1.5px" : "1px",
            borderRightColor: isOportunity ? theme.primary : "transparent",
            h: isOportunity ? "44px" : "38px",
          }}
          errorBorderColor={theme.error}
          bg="white"
          maxLength={maxLength}
        />
      </InputGroup>

      {!isOportunity && (
        <Box display={"flex"} w={"180px"}>
          <Button
            onClick={onClickButton}
            disabled={disabledButton}
            isDisabled={disabledButton}
            _hover={{ opacity: 1 }}
            border={borderTheme()}
            h={"40px"}
            borderRadius={"8px"}
            borderLeftRadius={0}
            fontSize={"14px"}
            fontWeight={"semibold"}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundColor={backgroundTheme()}
            _disabled={{ bg: theme.disable }}
            color={textTheme()}
            opacity={0.8}
          >
            {loadingButton ? <Spinner /> : "Buscar"}
          </Button>
        </Box>
      )}
    </Flex>
  );
};
export default InputSearch;
