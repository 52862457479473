import React from "react";
import * as Styled from "../stylesStepTab";
import { FiPlus } from "react-icons/fi";
import { BsPatchCheck, BsClockHistory } from "react-icons/bs";
import Button from "@components/common/Button";
import OportunityService from "@services/Oportunity/OportunityService";
import Spinner from "@components/common/Spinner";
import { theme } from "src/styles/theme";
import { OportunityContext } from "@context/oportunityContex";
import Modal from "@components/Sales/Modal";
import { RiCalendarEventLine, RiCalendarCheckLine } from "react-icons/ri";
import { MdOutlineEditNote } from "react-icons/md";
import ViewScheduleComponent from "./ViewScheduleComponent";
import { ISeeScheduleComponent } from "src/@types/Sales";

const SeeScheduleComponent = ({
  index,
  indexFirstTab,
  navigateCreateSchedule,
  successCreateSchedule,
  updateData,
  showDetailSchedule,
  setShowDetailSchedule,
}: ISeeScheduleComponent) => {
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const {
    faseOp,
    setAccessSchedule,
    accessSchedule,
    accessCreateOp,
    successCreateSchedule: successCreateScheduleCtx,
    setSuccessCreateSchedule,
  } = React.useContext(OportunityContext);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [edit, setEdit] = React.useState(false);
  const [deleteSchedule, setDeleteSchedule] = React.useState(false);

  const filteredRows = React.useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data;
  }, [data]);

  const handleSplitData = (value: string) => {
    if (!value) return "";

    const [data] = value.split(" ");

    return data;
  };

  const handleIcon = (visitaRealizada: boolean) => {
    if (visitaRealizada)
      return <RiCalendarCheckLine color="white" fontSize={"24px"} />;

    return <RiCalendarEventLine color="white" fontSize={"24px"} />;
  };

  React.useEffect(() => {
    const handleGetSchedule = async () => {
      if (index !== 1 || indexFirstTab !== 1) return;

      setLoading(true);
      try {
        const response = await OportunityService.getSchedule({
          ContactIdSalesforce: idCorretor,
          OpportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        });

        if (response.status === 401) {
          setLoading(false);
          return;
        }

        setData(Array.isArray(response) ? response : []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("ErrorHandleGetSchedule", error);
      } finally {
        setLoading(false);
      }
    };

    handleGetSchedule();
  }, [
    index,
    indexFirstTab,
    accessCreateOp.oportunityIdSalesforce,
    successCreateSchedule,
    deleteSchedule,
  ]);

  return (
    <>
      <Modal
        isModalDetail
        onOpen={showDetailSchedule || successCreateScheduleCtx}
        title={accessSchedule.codigoDoAgendamento}
        onClose={() => {
          setShowDetailSchedule(false);
          setSuccessCreateSchedule(false);
          setEdit(false);
        }}
        colorBgIcon={
          accessSchedule.visitaRealizada ? theme.primary : theme.disable
        }
        iconTitle={handleIcon(!!accessSchedule.visitaRealizada)}
      >
        <ViewScheduleComponent
          onBack={() => setShowDetailSchedule(false)}
          idAgendamentoSalesforce={accessSchedule.idAgendamentoSalesforce}
          isEdit={edit}
          setIsEdit={setEdit}
          setShowDetailSchedule={setShowDetailSchedule}
          setDeleteSchedule={setDeleteSchedule}
        />
      </Modal>

      <Styled.ContainerHistoricSchedule>
        <Styled.ContainerHeader>
          <Styled.ContaineButtonsHeader>
            <Button
              title={
                <Styled.TitleButon>
                  <FiPlus />
                  NOVO AGENDAMENTO
                </Styled.TitleButon>
              }
              onClick={navigateCreateSchedule}
              disabled={faseOp}
            />
          </Styled.ContaineButtonsHeader>
        </Styled.ContainerHeader>

        {loading ? (
          <Styled.ContainerTodoHistoricLoading>
            <Spinner />
          </Styled.ContainerTodoHistoricLoading>
        ) : (
          <>
            <Styled.ContainerTitleLabelHistoric>
              {filteredRows.length === 0 ? (
                <Styled.ContainerTodoHistoricEmpty>
                  <span>Não há agendamento nesta oportunidade.</span>
                </Styled.ContainerTodoHistoricEmpty>
              ) : (
                <>
                  <Styled.TitleLabelHistoricLeft>
                    Agendamento(s)
                  </Styled.TitleLabelHistoricLeft>
                  <Styled.TitleLabelHistoricRight>
                    Data/Status
                  </Styled.TitleLabelHistoricRight>
                </>
              )}
            </Styled.ContainerTitleLabelHistoric>

            <Styled.ContainerTodoHistoric>
              {filteredRows?.map(
                ({
                  idAgendamentoSalesforce,
                  codigoDoAgendamento,
                  visitaRealizada,
                  dataAgendamento,
                  dataDeComparecimento,
                  nomeEmpreendimento,
                  nomePDV,
                }) => (
                  <Styled.ContainerScheduleSeeSchedule
                    key={idAgendamentoSalesforce}
                  >
                    <Styled.ContainerIconTextHistoric>
                      <Styled.ContainerIconHistoric
                        visitaRealizada={visitaRealizada}
                      >
                        {handleIcon(visitaRealizada)}
                      </Styled.ContainerIconHistoric>
                      <Styled.ContainerTextHistoric>
                        <Styled.TitleTextHistoric>
                          {codigoDoAgendamento}
                        </Styled.TitleTextHistoric>
                        <Styled.SubTitleTextHistoric>
                          PDV: {nomePDV} / Empreendimento: {nomeEmpreendimento}
                        </Styled.SubTitleTextHistoric>
                      </Styled.ContainerTextHistoric>
                    </Styled.ContainerIconTextHistoric>

                    <Styled.ContainerDataStatus>
                      <Styled.ContainerIconTextDataStatus>
                        <Styled.ContainerTexDataStatus>
                          <Styled.TitleTexDataStatus
                            visitaRealizada={visitaRealizada}
                          >
                            {visitaRealizada
                              ? " Visita realizada"
                              : "Data da visita:"}
                          </Styled.TitleTexDataStatus>
                          <Styled.SubTitleTextDataStatus
                            visitaRealizada={visitaRealizada}
                          >
                            {visitaRealizada
                              ? handleSplitData(dataDeComparecimento)
                              : handleSplitData(dataAgendamento)}
                          </Styled.SubTitleTextDataStatus>
                        </Styled.ContainerTexDataStatus>
                        <Styled.ContainerIconDataStatus
                          visitaRealizada={visitaRealizada}
                        >
                          {visitaRealizada ? (
                            <BsPatchCheck />
                          ) : (
                            <BsClockHistory />
                          )}
                        </Styled.ContainerIconDataStatus>
                      </Styled.ContainerIconTextDataStatus>

                      <Styled.BoxDivider>
                        <Styled.DividerActivity />
                      </Styled.BoxDivider>

                      <Styled.ContainerButtonDataStatus>
                        <Styled.ContainerButtonIconDataStatus>
                          <Styled.AreaButtonIconDataTasksCalls
                            faseOp={faseOp}
                            onClick={() => {
                              if (faseOp) {
                                return;
                              } else {
                                setEdit(true);
                                setAccessSchedule({
                                  codigoDoAgendamento,
                                  idAgendamentoSalesforce,
                                  visitaRealizada,
                                });
                                setShowDetailSchedule(true);
                              }
                            }}
                          >
                            <span>Editar</span>
                            <MdOutlineEditNote />
                          </Styled.AreaButtonIconDataTasksCalls>
                        </Styled.ContainerButtonIconDataStatus>

                        <Styled.ContainerButtonView>
                          <Button
                            title="VISUALIZAR"
                            onClick={() => {
                              setAccessSchedule({
                                codigoDoAgendamento,
                                idAgendamentoSalesforce,
                                visitaRealizada,
                              });
                              setShowDetailSchedule(true);
                            }}
                          />
                        </Styled.ContainerButtonView>
                      </Styled.ContainerButtonDataStatus>
                    </Styled.ContainerDataStatus>
                  </Styled.ContainerScheduleSeeSchedule>
                )
              )}
            </Styled.ContainerTodoHistoric>
          </>
        )}
      </Styled.ContainerHistoricSchedule>
    </>
  );
};

export default SeeScheduleComponent;
